import React from 'react'
import { useTranslation } from 'react-i18next';
import selectLanguage from '../../assets/gif/Select_Language.gif'
import selectFile from '../../assets/gif/Select_File.gif'
import moreLanguages from '../../assets/gif/More_Languages.gif'
import speakerLabels from '../../assets/gif/Speaker_Labels.gif'
import fileType from '../../assets/gif/File_Type.gif'
import gettingTranscript from '../../assets/gif/Getting_Transcript.gif'
import wordSpeakerLabels from '../../assets/transcripts/Interview_Sonic_Transcript_Speaker_Labels.docx'
import pdfSpeakerLabels from '../../assets/transcripts/Interview_Sonic_Transcript_Speaker_Labels.pdf'
import txtSpeakerLabels from '../../assets/transcripts/Interview_Sonic_Transcript_Speaker_Labels.txt'
import word from '../../assets/transcripts/Interview_Sonic_Transcript.docx'
import pdf from '../../assets/transcripts/Interview_Sonic_Transcript.pdf'
import txt from '../../assets/transcripts/Interview_Sonic_Transcript.txt'
import mp3 from '../../assets/mp3/Sonic_Transcript_Interview.mp3'
import './faq.css'


function HowSonicTranscriptWorks() {

    const { t } = useTranslation("global");
    const string = 'FAQ.howSTWorks'

    const items = [
        { 
            image: selectFile,
            header: t(string + '.header1'),
            text: t(string + '.text1'),
        },
        { 
            image: fileType,
            header: t(string + '.header2'),
            text: t(string + '.text2'),
        },
        { 
            image: selectLanguage, 
            header: t(string + '.header3'),
            text: t(string + '.text3'),
        },
        { 
            image: moreLanguages, 
            header: t(string + '.header4'),
            text: t(string + '.text4'),
        },
        { 
            image: gettingTranscript, 
            header: t(string + '.header5'),
            text: t(string + '.text5'),
            downloads: [
                { type: 'text', link: txt, name: t(string + '.name1') },
                { type: 'pdf', link: pdf, name: t(string + '.name2')},
                { type: 'word', link: word, name: t(string + '.name3') }
            ]
        },
        { 
            image: speakerLabels, 
            header: t(string + '.header6'),
            text: t(string + '.text6'),
            downloads: [
                { type: 'text', link: txtSpeakerLabels, name: t(string + '.name4')},
                { type: 'pdf', link: pdfSpeakerLabels, name: t(string + '.name5') },
                { type: 'word', link: wordSpeakerLabels, name: t(string + '.name6') }
            ]
        },
    ];


  return (
    <div>
        {items.map((item, index) => (
            <div key={index} style={{padding: '20px', justifyContent: 'center'}}>
                <h2 style={{textAlign: 'center', margin: '20px'}}>{item.header}</h2>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={item.image} alt={item.text} style={{width: '90%', height: 'auto'}} />
                </div>
                <p className='faqText'>{item.text}</p>
                {item.downloads && (
                    <div className="download-container">
                        {item.downloads.map((download, index) => (
                            <a key={index} href={download.link} download={download.name} className={`download-link ${download.type}`}>
                                <img src={require(`../../assets/icons/${download.type}.png`)} alt={`${download.type} icon`} style={{height: '20px', width: '20px'}} />
                                 {t(string + '.download')} {download.type}
                            </a>
                        ))}
                    </div>
                )}
            </div>
        ))}
        <div style={{padding: '20px', justifyContent: 'center'}}>
            <p className='faqText' style={{margin: '10px'}}>If you'd like to try Sonic Transcript, feel free to use our audio file!</p>
            <a href={mp3} download={'Sonic Transcript Audio File'}className='download-link text'>
                <img src={require(`../../assets/icons/mp3.png`)} alt={`mp3 icon`} style={{height: '20px', width: '20px'}} />
                {t(string + '.downloadSample')} 
            </a>
        </div>
    </div>
  )
}

export default HowSonicTranscriptWorks