import React, {useRef, useState} from 'react'
import logo from '../../assets/stLogo.png'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './header.css'

const Header = () => {

  const { t } = useTranslation("global");

  const subText = t('header.subTitle');
  const promptText = t('header.promoText');
  // const updateText = 'Different pricing for Brazil and Mexico!'
  const emailRef = useRef();
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previousEmail, setPreviousEmail] = useState("");
  

  const addToMailchimp = async (email) => {
    setIsSubmitting(true);
    try {
      const response = await fetch(`https://us-central1-sonic-transcript-410518.cloudfunctions.net/mailChimp`, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `email=${encodeURIComponent(email)}`,
      });
  
      const responseData = await response;
      setEmailSent(t('header.emailSent1'));

      // setTimeout(() => {
        navigate('/signup', {state: { signUp: true, getStarted: t('header.getStartedText'), email: email}});
      // }, 1000);
    } catch (error) {
      // console.error(error);
      setEmailSent(t('header.emailSent2'));
      
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleEmail = () => {
    const email = emailRef.current.value;
    
      // Email validation regex
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

    if (!emailRegex.test(email)) {
      alert(t('header.validEmail'));
      return;
    }

    if (email === previousEmail && previousEmail !== "" && emailSent === t('header.emailSent1')) {
      alert(t('header.alreadySignedUp'));
      return;
    }

    setPreviousEmail(email);
    addToMailchimp(email);
  }
  

  return (
    <div className='gpt3__header' id='home'>
      <div className='gpt3__header-content'>
        <h1 className='gradient__text'>{t('header.title')}</h1>
        <p>{subText}</p>
        <br />
        <p>{t('header.promo2p1')}<strong style={{fontSize: 24}}>{'>'}{t('header.promo2p2')}</strong>{t('header.promo2p3')}</p>
        <br />
        <p><strong>{t('header.newFeature')}</strong></p>
        <br />
        <p>{promptText}</p>
        <div className='gpt3__header-content__input'>
          <input type='email' ref={emailRef}  placeholder={t('header.enterEmail')} />
          <button type="button" onClick={handleEmail} disabled={isSubmitting}>{isSubmitting ? t('header.submitButton1') : t('header.submitButton2')}</button>
        </div>
        {/* <div className='gpt3__header-content__people'>
          <img src={people} alt='people' />
          <p>1600 people requested access</p>
        </div> */}
        <p>{emailSent}</p>
      </div>
      <div className='gpt3__header-image'>
        <img src={logo} alt='ai' />
      </div>
    </div>
  )
}

export default Header