import React from 'react'
import { useTranslation } from 'react-i18next';
import {allowedTypes} from '../../utils'
import './aaf.css' 
// acceptable audio formats

const AAF = () => {

  const filteredAudioTypes = allowedTypes
  .filter((type, index, self) => type.split('/')[0] === 'audio' && self.indexOf(type) === index)
  .sort();

const filteredVideoTypes = allowedTypes
  .filter((type, index, self) => type.split('/')[0] === 'video' && self.indexOf(type) === index)
  .sort();

  const { t } = useTranslation("global");

  return (
    <div>
      <h1>{t('aaf.audioOrVideo')}</h1>
      <div className='aaf-container'>
        <div>
          <h3>{t('aaf.audio')}</h3>
          <ul className="audio-list">
            {filteredAudioTypes.map((type) => (
              <li key={type}>{type.split('/')[1]}</li>
            ))}
          </ul>
        </div>
        <div>
          <h3>{t('aaf.video')}</h3>
          <ul className="video-list">
            {filteredVideoTypes.map((type) => (
              <li key={type}>{type.split('/')[1]}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};



export default AAF