import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc, updateDoc, addDoc, collection, increment } from "firebase/firestore";
import { AssemblyAI } from 'assemblyai'
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import htmlToDocx from 'html-docx-js/dist/html-docx';
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import './audiodrop.css';
import {auth, db} from '../../firebase';
import { allowedTypes } from "../../utils";
// import { set } from 'firebase/database';

const client = new AssemblyAI({
    apiKey: "8ae9beb244534c0195280eeec5af6377"
  })

const AudioDrop = ({handleDisabledTab, audioDropState, updateAudioDropState}) => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(audioDropState.selectedFile);
    const [fileName, setFileName] = useState(audioDropState.fileName);
    const [dragging, setDragging] = useState(false);
    const [disable, setDisable] = useState(audioDropState.disabled);
    const [status, setStatus] = useState(audioDropState.status);
    const [fileMinutes, setFileMinutes] = useState(audioDropState.fileMinutes);
    const [selectedOption, setSelectedOption] = useState(audioDropState.selectedOption);
    const [selectedOptionLanguageNoSpeakerLabels, setSelectedOptionLanguageNoSpeakerLabels] = useState(audioDropState.selectedOptionLanguageNoSpeakerLabels);
    const [selectMoreLanguages, setSelectMoreLanguages] = useState(audioDropState.selectMoreLanguages);
    const [moreLanguageBox, setMoreLanguageBox] = useState(audioDropState.moreLanguageBox);

    const { t } = useTranslation("global");

    const options = [
        { value: ["text"], label: t('audioDrop.options.textFile') },
        { value: ["pdf"], label: t('audioDrop.options.pdf') },
        { value: ["word"], label: t('audioDrop.options.wordDocument') },
        { value: ["text", "pdf"], label: t('audioDrop.options.textAndPdf') },
        { value: ["text", "word"], label: t('audioDrop.options.textAndWord') },
        { value: ["pdf", "word"], label: t('audioDrop.options.pdfAndWord') },
        { value: ["text", "pdf", "word"], label: t('audioDrop.options.all') },
    ]
    
    const languageOptionsNoSpeakerLabels = [
        { value: 'en', label: t('audioDrop.languages.en') },
        { value: 'en_au', label: t('audioDrop.languages.en_au') },
        { value: 'en_uk', label: t('audioDrop.languages.en_uk') },
        { value: 'en_us', label: t('audioDrop.languages.en_us') },
        { value: 'es', label: t('audioDrop.languages.es') },
        { value: 'fr', label: t('audioDrop.languages.fr') },
        { value: 'de', label: t('audioDrop.languages.de') },
        { value: 'it', label: t('audioDrop.languages.it') },
        { value: 'pt', label: t('audioDrop.languages.pt') },
        { value: 'nl', label: t('audioDrop.languages.nl') },
        { value: 'fi', label: t('audioDrop.languages.fi') },
        { value: 'pl', label: t('audioDrop.languages.pl') },
        { value: 'ru', label: t('audioDrop.languages.ru') },
        { value: 'tr', label: t('audioDrop.languages.tr') },
        { value: 'hi', label: t('audioDrop.languages.hi') },
        { value: 'ja', label: t('audioDrop.languages.ja') },
        { value: 'zh', label: t('audioDrop.languages.zh') },
        { value: 'ko', label: t('audioDrop.languages.ko') },
        { value: 'uk', label: t('audioDrop.languages.uk') },
        { value: 'vi', label: t('audioDrop.languages.vi') },
    ].sort((a, b) => a.label.localeCompare(b.label));
    
    const languagesMoreOptions = [
        { value: 'en', label: t('audioDrop.languages.en') },
        { value: 'en_au', label: t('audioDrop.languages.en_au') },
        { value: 'en_uk', label: t('audioDrop.languages.en_uk') },
        { value: 'en_us', label: t('audioDrop.languages.en_us') },
        { value: 'es', label: t('audioDrop.languages.es') },
        { value: 'fr', label: t('audioDrop.languages.fr') },
        { value: 'de', label: t('audioDrop.languages.de') },
        { value: 'it', label: t('audioDrop.languages.it') },
        { value: 'pt', label: t('audioDrop.languages.pt') },
        { value: 'nl', label: t('audioDrop.languages.nl') },
        { value: 'af', label: t('audioDrop.languages.af') },
        { value: 'sq', label: t('audioDrop.languages.sq') },
        { value: 'am', label: t('audioDrop.languages.am') },
        { value: 'ar', label: t('audioDrop.languages.ar') },
        { value: 'hy', label: t('audioDrop.languages.hy') },
        { value: 'as', label: t('audioDrop.languages.as') },
        { value: 'az', label: t('audioDrop.languages.az') },
        { value: 'ba', label: t('audioDrop.languages.ba') },
        { value: 'eu', label: t('audioDrop.languages.eu') },
        { value: 'be', label: t('audioDrop.languages.be') },
        { value: 'bn', label: t('audioDrop.languages.bn') },
        { value: 'bs', label: t('audioDrop.languages.bs') },
        { value: 'br', label: t('audioDrop.languages.br') },
        { value: 'bg', label: t('audioDrop.languages.bg') },
        { value: 'my', label: t('audioDrop.languages.my') },
        { value: 'ca', label: t('audioDrop.languages.ca') },
        { value: 'zh', label: t('audioDrop.languages.zh') },
        { value: 'hr', label: t('audioDrop.languages.hr') },
        { value: 'cs', label: t('audioDrop.languages.cs') },
        { value: 'da', label: t('audioDrop.languages.da') },
        { value: 'et', label: t('audioDrop.languages.et') },
        { value: 'fo', label: t('audioDrop.languages.fo') },
        { value: 'fi', label: t('audioDrop.languages.fi') },
        { value: 'gl', label: t('audioDrop.languages.gl') },
        { value: 'ka', label: t('audioDrop.languages.ka') },
        { value: 'el', label: t('audioDrop.languages.el') },
        { value: 'gu', label: t('audioDrop.languages.gu') },
        { value: 'ht', label: t('audioDrop.languages.ht') },
        { value: 'ha', label: t('audioDrop.languages.ha') },
        { value: 'haw', label: t('audioDrop.languages.haw') },
        { value: 'he', label: t('audioDrop.languages.he') },
        { value: 'hi', label: t('audioDrop.languages.hi') },
        { value: 'hu', label: t('audioDrop.languages.hu') },
        { value: 'is', label: t('audioDrop.languages.is') },
        { value: 'id', label: t('audioDrop.languages.id') },
        { value: 'ja', label: t('audioDrop.languages.ja') },
        { value: 'jw', label: t('audioDrop.languages.jw') },
        { value: 'kn', label: t('audioDrop.languages.kn') },
        { value: 'kk', label: t('audioDrop.languages.kk') },
        { value: 'km', label: t('audioDrop.languages.km') },
        { value: 'ko', label: t('audioDrop.languages.ko') },
        { value: 'lo', label: t('audioDrop.languages.lo') },
        { value: 'la', label: t('audioDrop.languages.la') },
        { value: 'lv', label: t('audioDrop.languages.lv') },
        { value: 'ln', label: t('audioDrop.languages.ln') },
        { value: 'lt', label: t('audioDrop.languages.lt') },
        { value: 'lb', label: t('audioDrop.languages.lb') },
        { value: 'mk', label: t('audioDrop.languages.mk') },
        { value: 'mg', label: t('audioDrop.languages.mg') },
        { value: 'ms', label: t('audioDrop.languages.ms') },
        { value: 'ml', label: t('audioDrop.languages.ml') },
        { value: 'mt', label: t('audioDrop.languages.mt') },
        { value: 'mi', label: t('audioDrop.languages.mi') },
        { value: 'mr', label: t('audioDrop.languages.mr') },
        { value: 'mn', label: t('audioDrop.languages.mn') },
        { value: 'ne', label: t('audioDrop.languages.ne') },
        { value: 'no', label: t('audioDrop.languages.no') },
        { value: 'nn', label: t('audioDrop.languages.nn') },
        { value: 'oc', label: t('audioDrop.languages.oc') },
        { value: 'pa', label: t('audioDrop.languages.pa') },
        { value: 'ps', label: t('audioDrop.languages.ps') },
        { value: 'fa', label: t('audioDrop.languages.fa') },
        { value: 'pl', label: t('audioDrop.languages.pl') },
        { value: 'ro', label: t('audioDrop.languages.ro') },
        { value: 'ru', label: t('audioDrop.languages.ru') },
        { value: 'sa', label: t('audioDrop.languages.sa') },
        { value: 'sr', label: t('audioDrop.languages.sr') },
        { value: 'sn', label: t('audioDrop.languages.sn') },
        { value: 'sd', label: t('audioDrop.languages.sd') },
        { value: 'si', label: t('audioDrop.languages.si') },
        { value: 'sk', label: t('audioDrop.languages.sk') },
        { value: 'sl', label: t('audioDrop.languages.sl') },
        { value: 'so', label: t('audioDrop.languages.so') },
        { value: 'su', label: t('audioDrop.languages.su') },
        { value: 'sw', label: t('audioDrop.languages.sw') },
        { value: 'sv', label: t('audioDrop.languages.sv') },
        { value: 'tl', label: t('audioDrop.languages.tl') },
        { value: 'tg', label: t('audioDrop.languages.tg') },
        { value: 'ta', label: t('audioDrop.languages.ta') },
        { value: 'tt', label: t('audioDrop.languages.tt') },
        { value: 'te', label: t('audioDrop.languages.te') },
        { value: 'th', label: t('audioDrop.languages.th') },
        { value: 'bo', label: t('audioDrop.languages.bo') },
        { value: 'tr', label: t('audioDrop.languages.tr') },
        { value: 'tk', label: t('audioDrop.languages.tk') },
        { value: 'uk', label: t('audioDrop.languages.uk') },
        { value: 'ur', label: t('audioDrop.languages.ur') },
        { value: 'uz', label: t('audioDrop.languages.uz') },
        { value: 'vi', label: t('audioDrop.languages.vi') },
        { value: 'cy', label: t('audioDrop.languages.cy') },
        { value: 'yi', label: t('audioDrop.languages.yi') },
        { value: 'yo', label: t('audioDrop.languages.yo') },
    ];

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };
    

    const handleDragLeave = (event) => {
        event.preventDefault();
        setDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragging(false);
        const file = event.dataTransfer.files[0];

        // if (file && allowedTypes.includes(file.type)) {
            setStatus(t('audioDrop.status1'))
            setSelectedFile(file);
            const audio  = new Audio();
            audio.src = URL.createObjectURL(file);
            audio.addEventListener('loadedmetadata', () => {
                setFileMinutes(Math.round(audio.duration / 60));
            });
            setFileName(file.name);
        // } else {
        //     // Handle invalid file type
        //     alert(t('audioDrop.incorrectFileType'));
        // }
    };

    const deleteTranscript = async (id) => {
        const response = await fetch('https://us-central1-sonic-transcript-410518.cloudfunctions.net/deleteTranscript2', {
            mode: 'no-cors',
            method: 'POST', // or 'GET', 'PUT', etc.
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `transcript_id=${encodeURIComponent(id)}`,
        });
        
        if (!response.ok) {
            // throw new Error('Network response was not ok');
        }
    }

    const createDocument = async (transcriptionText, utterances, type, fileName, speakerLabels) => {
        switch (type) {
            case "text":
                const blob = new Blob([transcriptionText], {type: "text/plain;charset=utf-8"});
                saveAs(blob, fileName+".txt");
                break;
            case "pdf":
                const doc = new jsPDF();
                doc.setFontSize(12); // Set the font size to 10 points
                const maxWidth = 180; // Maximum width in points
                const lineHeight = 10; // Line height in points, adjust as needed
                const pageHeight = doc.internal.pageSize.height; // Page height in points
                const lines = doc.splitTextToSize(transcriptionText, maxWidth); // Split the text
                let y = 20; // Initial y position, adjust as needed
                for (let i = 0; i < lines.length; i++) {
                    if (y + lineHeight > pageHeight) {
                        doc.addPage();
                        y = 20; // Reset y position to top of the new page, adjust as needed
                    }
                    doc.text(lines[i], 20, y); // Adjust the x position as needed
                    y += lineHeight;
                }
                const blob2 = doc.output('blob');
                saveAs(blob2, fileName+".pdf");
                break;
            case "word":
                // Convert the text to HTML
                var html
                if(speakerLabels) {
                    html = utterances.map(utterance => { return `<p>Speaker ${utterance.speaker}: ${utterance.text}</p>` }).join('');
                } else {
                    html = `<p>${transcriptionText}</p>`;
                }
 
                // Convert the HTML to a Word document
                const docx = htmlToDocx.asBlob(html);
                saveAs(docx, fileName+'.docx');
                break;
            default:
                break;
        }
    }

    const handleSelectChange = option => {
        if (window.fbq) {
            window.fbq('trackCustom', 'FileType', {content_name: option.label});
        }
        setSelectedOption(option);
    };

    const handleSelectLanguageNoSpeakerLabelsChange = option => {
        if (window.fbq) {
            window.fbq('trackCustom', 'LanguageTypeNoSpeakerLabels', {content_name: option.label});
        }
        setSelectMoreLanguages(null);
        setSelectedOptionLanguageNoSpeakerLabels(option);
    };

    const handleMoreLanguageChange = option => {
        if (window.fbq) {
            window.fbq('trackCustom', 'MoreLanguage', {content_name: option.label});
        }
        setSelectedOptionLanguageNoSpeakerLabels(null);
        setSelectMoreLanguages(option);
    };

    const handleUpload = async () => {
        if (auth.currentUser == null) {
            alert(t('audioDrop.signInAlert'));
            navigate('/signup')
            return;
        }
        // if there is a transcription in progress, get the transcript id
        if(!auth.currentUser.emailVerified){
            alert(t('audioDrop.verifyEmail'));
            navigate('/profile')
            return;
        };
       
        if (selectedFile) {
            const uid = auth.currentUser.uid;
            const audio = new Audio();
            audio.src = URL.createObjectURL(selectedFile);
            audio.addEventListener('loadedmetadata', async () => {
                const duration = audio.duration;
                // Check if user has enough minutes
                const userDoc = await getDoc(doc(db, "users", uid));
                const userData = userDoc.data();
                const corporation = userData.corporation;
                let userMinutes = userData.minutes;
                if(corporation) {
                    const corpDoc = await getDoc(doc(db, "corporations", corporation));
                    userMinutes = corpDoc.data().minutes;
                }

                var fileMinutesNow = Math.round(duration / 60);
                setFileMinutes(fileMinutesNow);

                if (userMinutes < fileMinutesNow) {
                    alert(t('audioDrop.notEnoughMinutes'));
                    navigate('/profile');
                    return;
                }
                if(selectedOption === null) {
                    alert(t('audioDrop.fileType'));
                    return;
                }


                const FILE_PATH = selectedFile;
                var data;
                // checks which model the user wants to use depending on the checkboxes
                if(moreLanguageBox) {
                    // checks if the language exists for the best model
                    const languageExists = languageOptionsNoSpeakerLabels.some(option => option.value === languagesMoreOptions.value);
                    if(languagesMoreOptions.value === undefined || languagesMoreOptions.value === null) {
                        if(languageExists) {
                            data = {
                                audio: FILE_PATH,
                                speaker_labels: false,
                                language_detection: true,
                            }
                        } else {
                            data = {
                                audio: FILE_PATH,
                                speaker_labels: false,
                                language_detection: true,
                                speech_model: 'nano',
                            }
                        }
                        
                    } else {
                        if(languageExists) {
                            data = {
                                audio: FILE_PATH,
                                speaker_labels: false,
                                language_code: languagesMoreOptions.value,
                            }
                        } else {
                            data = {
                                audio: FILE_PATH,
                                speaker_labels: false,
                                language_code: languagesMoreOptions.value,
                                speech_model: 'nano',
                            }
                        }
                    }
                } else {
                    if(selectedOptionLanguageNoSpeakerLabels.value === undefined || selectedOptionLanguageNoSpeakerLabels.value === null) {
                        data = {
                            audio: FILE_PATH,
                            speaker_labels: false,
                            language_detection: true,
                        }
                    } else {
                        data = {
                            audio: FILE_PATH,
                            speaker_labels: false,
                            language_code: selectedOptionLanguageNoSpeakerLabels.value,
                        }
                    }
                }
                if(window.fbq) {
                    window.fbq('trackCustom', 'UploadedFile');
                }

                setStatus(t('audioDrop.status2'))
                setDisable(true);
                handleDisabledTab(true);
                let transcript = await client.transcripts.submit(data);

                // create an interval to check the status of the transcript
                secureLocalStorage.setItem("transcriptInfo", JSON.stringify({
                    id: transcript.id,
                    userMinutes: userMinutes,
                    fileMinutes: fileMinutesNow,
                    fileName: fileName,
                    corporation: corporation
                }));

                const interval = setInterval(async () => {
                    const updatedTranscript = await client.transcripts.get(transcript.id);
                    switch (updatedTranscript.status) {
                        case 'queued':
                            setStatus(t('audioDrop.status4'))
                            break;
                        case 'processing':
                            setStatus(t('audioDrop.status3'))
                            break;
                        case 'completed':
                            setStatus(t('audioDrop.status5'))
                            clearInterval(interval);
                            var transcriptionText = '';
                            const fileName = selectedFile.name.split('.')[0];
                            if(corporation) {
                                await addDoc(collection(db, "corporations", corporation, "log"), {
                                    fileName: fileName,
                                    fileType: selectedOption.value,
                                    date: new Date(),
                                    fileMinutes: fileMinutes,
                                    totalMinutes: userMinutes,
                                    language: updatedTranscript.language_code,
                                    email: auth.currentUser.email,
                                    serviceType: t('audioDrop.transcription'),
                                });
                                await updateDoc(doc(db, "corporations", corporation), {
                                    minutes: increment(-fileMinutesNow),
                                });
                            } else {
                                await addDoc(collection(db, "users", uid, "log"), {
                                    fileName: fileName,
                                    fileType: selectedOption.value,
                                    date: new Date(),
                                    fileMinutes: fileMinutes,
                                    totalMinutes: userMinutes,
                                    language: updatedTranscript.language_code,
                                    serviceType: t('audioDrop.transcription'),
                                });
                                await updateDoc(doc(db, "users", uid), {
                                    minutes: increment(-fileMinutesNow),
                                });
                            }
                            // change how the text is created in the document
                            transcriptionText = updatedTranscript.text;
                            selectedOption.value.forEach(type => {
                                createDocument(transcriptionText, updatedTranscript.utterances, type, fileName, false);
                            });
                            
                            deleteTranscript(transcript.id);
                            secureLocalStorage.removeItem("transcriptInfo");
                            setDisable(false);
                            handleDisabledTab(false);
                            break;
                        case 'error':
                            setStatus(t('audioDrop.status6'))
                            clearInterval(interval);
                            // deleteTranscript(transcript.id);
                            // await client.transcripts.delete(transcript.id);
                            // secureLocalStorage.removeItem("transcriptInfo");
                            setDisable(false);
                            handleDisabledTab(false);
                            break;
                        default:
                            setStatus(t('audioDrop.status7'))
                            break;
                    }
                }, 5000); // Check the status every 5 seconds
  
        });
    };
    };

    const handleSelectFile = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        console.log("file type is", file.type);

        // if (file && allowedTypes.includes(file.type)) {
            setStatus(t('audioDrop.status1'))
            setSelectedFile(file);
            setFileName(file.name);
            const audio  = new Audio();
            audio.src = URL.createObjectURL(file);
            audio.addEventListener('loadedmetadata', () => {
                setFileMinutes(Math.round(audio.duration / 60));
            });
        // } else {
            // Handle invalid file type
            // alert(t('audioDrop.incorrectFileType'));
        // }
        // setSelectedFile(file);
        // setStatus("");
    };

    const handleReset = () => {
        setSelectedFile(null);
        setSelectedOption(null);
        setSelectedOptionLanguageNoSpeakerLabels(null);
        setSelectMoreLanguages(null);
        setFileName("");
        setStatus("");
        setFileMinutes(0);
        setMoreLanguageBox(false);
    }

    useEffect(() => {
        const info = JSON.parse(secureLocalStorage.getItem("transcriptInfo"));
        
        if (info != null) {
            setDisable(true);
            handleDisabledTab(true);
            const id = info.id;
            const userMinutes = info.userMinutes;
            const fileMinutes = info.fileMinutes;  
            const fileName = info.fileName.split('.')[0];
            const corporation = info.corporation;

            setFileName(fileName);
            setStatus(t('audioDrop.status8'))
            const interval = setInterval(async () => {
                const updatedTranscript = await client.transcripts.get(id);
                switch (updatedTranscript.status) {
                    case 'queued':
                        setStatus(t('audioDrop.status4'))
                        break;
                    case 'processing':
                        setStatus(t('audioDrop.status3'))
                        break;
                    case 'completed':
                        setStatus(t('audioDrop.status5'))
                        setDisable(false);
                        handleDisabledTab(false);
                        secureLocalStorage.removeItem("transcriptInfo");
                        clearInterval(interval);
                        var transcriptionText = '';
                        const fileName = selectedFile.name.split('.')[0];
                        if(corporation) {
                            await addDoc(collection(db, "corporations", corporation, "log"), {
                                fileName: fileName,
                                fileType: selectedOption.value,
                                date: new Date(),
                                fileMinutes: fileMinutes,
                                totalMinutes: userMinutes,
                                language: updatedTranscript.language_code,
                                email: auth.currentUser.email,
                                serviceType: t('audioDrop.transcription'),
                            });
                            await updateDoc(doc(db, "corporations", corporation), {
                                minutes: increment(-fileMinutes),
                            });
                        } else {
                            await addDoc(collection(db, "users", auth.currentUser.uid, "log"), {
                                fileName: fileName,
                                fileType: selectedOption.value,
                                date: new Date(),
                                fileMinutes: fileMinutes,
                                totalMinutes: userMinutes,
                                language: updatedTranscript.language_code,
                                serviceType: t('audioDrop.transcription'),
                            });
                            await updateDoc(doc(db, "users", auth.currentUser.uid), {
                                minutes: increment(-fileMinutes),
                            });
                        }
                        // change how the text is created in the document
                        transcriptionText = updatedTranscript.text;
                        selectedOption.value.forEach(type => {
                            createDocument(transcriptionText, updatedTranscript.utterances, type, fileName, false);
                        });
                        deleteTranscript(id);
                        // await client.transcripts.delete(id);
                        break;
                    case 'error':
                        setStatus(t('audioDrop.status6'))
                        // deleteTranscript(id);
                        // secureLocalStorage.removeItem("transcriptInfo");
                        clearInterval(interval);
                        // await client.transcripts.delete(id);
                        setDisable(false);
                        handleDisabledTab(false);
                        break;
                    default:
                        setStatus(t('audioDrop.status7'))
                        break;
                }
            }, 5000);
        }
    }, [ selectedOption,selectedOptionLanguageNoSpeakerLabels, selectMoreLanguages, selectedFile]);   
    
    const [timer, setTimer] = useState(0);
    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
   
    useEffect(() => {
        let interval;
        if (status === t('audioDrop.status3') || status === t('audioDrop.status4')) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }
        if(status === t('audioDrop.status5')) {
            setTimer(0);
        }
        return () => {
            clearInterval(interval);
        };
    }, [status, t]);

    useEffect(() => {
        updateAudioDropState({
            selectedFile: selectedFile,
            fileName: fileName,
            fileMinutes: fileMinutes,
            disabled: disable,
            status: status,
            selectedOption: selectedOption,
            selectedOptionLanguageNoSpeakerLabels: selectedOptionLanguageNoSpeakerLabels,
            selectMoreLanguages: selectMoreLanguages,
            moreLanguageBox: moreLanguageBox,
        });
    }, [selectedFile, fileName, fileMinutes, disable, status, selectedOption, selectedOptionLanguageNoSpeakerLabels, selectMoreLanguages, moreLanguageBox]);

    return (
        <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className='container'
            style={{ border: dragging ? '2px solid blue' : '2px solid gray'}}
        >
            {selectedFile || fileName ? (
                <div className='dragging'>
                    <p className='status'>{t('audioDrop.status')}<strong>{status}</strong></p>
                    <p>{t('audioDrop.selectedFile')}<strong>{fileName}</strong></p>
                    <p>{t('audioDrop.fileMinutes')}<strong>{fileMinutes}</strong></p>
                    <p><strong>{t('audioDrop.note')}</strong>{t('audioDrop.noteText')}</p>
                    <Select
                        value={selectedOption}
                        onChange={handleSelectChange}
                        options={options}
                        defaultValue={options[0]}
                        styles={{
                            option: (provided) => ({
                              ...provided,
                              textAlign: 'center'
                            }),
                            control: (provided) => ({
                              ...provided,
                              maxHeight: '50px',
                              marginTop: '10px',
                              zIndex: 1000,
                            }),
                          }}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                        placeholder={t('audioDrop.placeHolderFiles')}
                    />
                    <p><strong>{t('audioDrop.warning')}</strong>{t('audioDrop.warningText')}</p>
                    <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                checked={moreLanguageBox}
                                onChange={(e) => setMoreLanguageBox(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <p>{t('audioDrop.moreLanguages')}</p>
                        </div>
                        {moreLanguageBox ? (
                                <>
                                <p><strong>{t('audioDrop.warning')}</strong>{t('audioDrop.moreLangWarningText')}</p>
                                    <Select
                                        value={selectMoreLanguages}
                                        onChange={handleMoreLanguageChange}
                                        options={languagesMoreOptions}
                                        defaultValue={languagesMoreOptions[0]}
                                        styles={{
                                            option: (provided) => ({
                                            ...provided,
                                            textAlign: 'center'
                                            }),
                                            control: (provided) => ({
                                            ...provided,
                                            maxHeight: '50px',
                                            marginTop: '10px',
                                            zIndex: 2000,
                                            }),
                                        }}
                                        menuPortalTarget={document.body}
                                        menuPosition='fixed'
                                        placeholder={t('audioDrop.selectLanguagePlaceholder')}
                                    />
                                </>
                            ):(
                                <Select
                                    value={selectedOptionLanguageNoSpeakerLabels}
                                    onChange={handleSelectLanguageNoSpeakerLabelsChange}
                                    options={languageOptionsNoSpeakerLabels}
                                    defaultValue={languageOptionsNoSpeakerLabels[0]}
                                    styles={{
                                        option: (provided) => ({
                                        ...provided,
                                        textAlign: 'center'
                                        }),
                                        control: (provided) => ({
                                        ...provided,
                                        maxHeight: '50px',
                                        marginTop: '10px',
                                        zIndex: 2000,
                                        }),
                                    }}
                                    menuPortalTarget={document.body}
                                    menuPosition='fixed'
                                    placeholder={t('audioDrop.selectLanguagePlaceholder')}
                                />
                            )}
                        
                    </>
                    
                    <div>
                        {(status === t('audioDrop.status3') || status === t('audioDrop.status4'))&& (
                            <p>{t('audioDrop.timeLabel')}<strong>{formatTime(timer)}</strong></p>
                        )}
                    </div>
                    <div>
                        <button className={"submitButton"} onClick={handleUpload} disabled={disable}>{t('audioDrop.send')}</button>
                        {status === t('audioDrop.status5') && (
                            <button className={"submitButton"} style={{marginLeft: 10}} onClick={handleReset}>{t('audioDrop.reset')}</button>
                        )}
                    </div>
                </div>
            ) : (
                <div className='insideUploadBox'>
                    <h2>{t('audioDrop.uploadTitle')}</h2>
                    <p>{t('audioDrop.uploadText')}</p>
                    <button className="submitButton" onClick={() => document.getElementById('fileInput').click()}>{t('audioDrop.selectFile')}</button>
                    <input type="file" id="fileInput" accept={allowedTypes.join(',')} style={{ display: 'none' }} onChange={handleSelectFile} />
                </div>
            )}
        </div>
    );

};

export default AudioDrop;