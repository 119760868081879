import React from 'react'
import { useTranslation } from 'react-i18next';
import './faq.css'

export default function PurchaseMinutes() {

  const { t } = useTranslation("global");
  const string = 'FAQ.purchaseMinutes.description'
  const purchaseInstructions = t(string)

  return (
    <div>
        <p className='faqText'>
          {purchaseInstructions}
        </p>
    </div>
  )
}
