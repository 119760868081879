import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslateDropBox, AudioDrop, AudioDropSL } from '../../components';
import './tabbox.css';

function TabBox() {
  const [activeTab, setActiveTab] = useState(0);
  const [disable, setDisable] = useState(false);
  const [audioDropState, setAudioDropState] = useState({
    "selectedFile": null,
    "fileName": "",
    "fileMinutes": 0,
    "disabled": false,
    "status": '',
    "selectedOption": null,
    "selectedOptionLanguageNoSpeakerLabels": null,
    "selectMoreLanguages": null,
    "moreLanguagesBox": false,
  });
  const [audioDropSLState, setAudioDropSLState] = useState({
    "selectedFile": null,
    "fileName": "",
    "fileMinutes": 0,
    "disabled": false,
    "status": '',
    "numSpeakers": 2,
    "selectedOption": null,
    "selectedOptionLanguage": null,
  });
  const [translateDropBoxState, setTranslateDropBoxState] = useState({
    "textToTranslate": "",
    "selectedFile": null,
    "fileName": "",
    "fileMinutes": 0,
    "selectLanguagesOptionsFrom": '',
    "selectLanguagesOptionsTo": '',
    "selectedOption": null,
    "uploadProgress": 0,
    "downloadProgress": 0,
    "switchProgressBar": false,
    "submitting": false,
    "disable": false,
    "error": null,
  });

  const { t } = useTranslation("global");

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const handleDisabledTab = (boolean) => {
    // setDisable(boolean);
  }
  // Function to update audioDropState
  const updateAudioDropState = (newProperties) => {
    setAudioDropState((prevState) => ({
      ...prevState,
      ...newProperties,
    }));
  };

  // Function to update audioDropSLState
  const updateAudioDropSLState = (newProperties) => {
    setAudioDropSLState((prevState) => ({
      ...prevState,
      ...newProperties,
    }));
  };

  // Function to update translateDropBoxState
  const updateTranslateDropBoxState = (newProperties) => {
    setTranslateDropBoxState((prevState) => ({
      ...prevState,
      ...newProperties,
    }));
  };

  return (
    <div className="tab-box">
      <div className="tabs">
        <button className={`tab-button ${activeTab === 0 ? 'active-tab-0' : ''}`} disabled={disable} onClick={() => handleTabClick(0)}>{t('tabBox.audioToText')}</button>
        <button className={`tab-button ${activeTab === 1 ? 'active-tab-1' : ''}`} disabled={disable} onClick={() => handleTabClick(1)}>{t('tabBox.audioToTextSL')}</button>
        <button className={`tab-button ${activeTab === 2 ? 'active-tab-2' : ''}`} disabled={disable} onClick={() => handleTabClick(2)}>{t('tabBox.translate')}</button>
    </div>
      <div className="tab-content">
        {activeTab === 0 && (
          <div className="drop-box">
            <AudioDrop 
              handleDisabledTab={handleDisabledTab}
              audioDropState={audioDropState}
              updateAudioDropState={updateAudioDropState}
            />
          </div>
        )}
        {activeTab === 1 && (
          <div className="drop-box">
            <AudioDropSL 
              handleDisabledTab={handleDisabledTab}
              audioDropSLState={audioDropSLState}
              updateAudioDropSLState={updateAudioDropSLState}
             />
          </div>
        )}
        {activeTab === 2 && (
          <div className="drop-box">
            <TranslateDropBox 
              handleDisabledTab={handleDisabledTab}
              translateDropBoxState={translateDropBoxState}
              updateTranslateDropBoxState={updateTranslateDropBoxState}
             />
          </div>
        )}
      </div>
    </div>
  );
}

export default TabBox;