

import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom'
import {getAuth, onAuthStateChanged, signOut} from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import {app} from '../../firebase';
import "./product.css";

const Product = ({title, prices, included}) => {

    const navigation = useNavigate();
    const [isSignedIn, setIsSignedIn] = useState(true);
    useEffect(() => {
        // Get the user's information from Firestore
        // console.log(auth.currentUser.uid, 'auth')
        const auth = getAuth(app);
        onAuthStateChanged(auth, (user) => {
          if (user) {
            // console.log(user, 'user')
            setIsSignedIn(true);
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
            const uid = user.uid;
            // ...
          } else {
            // User is signed out
            setIsSignedIn(false);
            // ...
          }
        });
        
      }, []);

      const { t } = useTranslation("global");

    const handlePurchase = () => { 
        if (isSignedIn) {
            // Navigate to user's profile
            navigation('/profile');
        } else {
            // Navigate to profile page for new users
            navigation('/signup');
        }
    };
    return (
        <div className="product-container">
            <h2 className="product-title">{title}</h2>
            <div className="price-box">
                {prices.map((price, index) => {
                    if(index === prices.length - 1) {
                        return <div key={index} className="price">{price}</div>
                    } else {
                        return <div key={index} className="price">{price},</div>
                    }
                })}
            </div>
            <ul className="product-features">
                {included.map((feature, index) => {
                    return (<li key={index}>{feature}</li>);
                })}
            </ul>
            <button className='product-button' onClick={handlePurchase}>{t("product.getNow")}</button>
        </div>
    );
    
};

export default Product;