import React from 'react';
import { Link  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from "../../assets/stLogo.png"
import './footer.css';

const Footer = () => {

  const { t } = useTranslation("global");

    return (
      <div className="gpt3__footer section__padding">
        <div className="gpt3__footer-heading">
          <h1 className="gradient__text">{t('footer.title')}</h1>
        </div>
    {/* 
        <div className="gpt3__footer-btn">
          <p>Request Early Access</p>
        </div> */}

        <div className="gpt3__footer-links">
          <div className="gpt3__footer-links_logo">
            <img src={logo} alt="logo" />
            {/* <p>Crechterwoord K12 182 DK Alknjkcb, <br /> All Rights Reserved</p> */}
          </div>
          {/* <div className="gpt3__footer-links_div">
            <h4>Links</h4>
            <p>Social Media</p>
            <p>Counters</p>
            <p>Contact</p>
          </div> */}
          <div className="gpt3__footer-links_div">
            <h4>Northern Lighthouse LLC</h4>
            <p><Link to={'/privacy'}>{t('footer.privacyPolicy')}</Link></p> {/* Comment out this line if it causes further issues with SEO */}
          </div>
          <div className="gpt3__footer-links_div">
            <h4>{t('footer.getInTouch')}</h4>
            <p>support@sonictranscript.com</p>
          </div>
        </div>

        <div className="gpt3__footer-copyright">
          <p>@2024 Sonic Transcript. {t('footer.allRights')}</p>
        </div>
      </div>
    );
  }
export default Footer;