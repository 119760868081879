// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, setPersistence, browserSessionPersistence, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import {doc, query, collection, where, getDocs, deleteDoc, setDoc, getDoc, getFirestore} from 'firebase/firestore';
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDfzd7-6iafbq7E8DSvdbiGmilYVu-fdMU",
  authDomain: "sonic-transcript.firebaseapp.com",
  projectId: "sonic-transcript",
  storageBucket: "sonic-transcript.appspot.com",
  messagingSenderId: "974427447093",
  appId: "1:974427447093:web:61fa0daab493092e24767c",
  measurementId: "G-FQXKYSFSR2"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
setPersistence(auth, browserSessionPersistence);
export const db = getFirestore(app);
export const storage = getStorage(app);

const addToMailChimp = async (email) => {
  // const authenticationString = `Basic ${btoa(`apikey:${mailchimpKey}`)}`;
  // console.log(authenticationString)
  try {
    const response = await fetch(`https://us-central1-sonic-transcript-410518.cloudfunctions.net/mailChimp`, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `email=${encodeURIComponent(email)}`,
    });

    const responseData = await response;
    // console.log(responseData);
  
  } catch (error) {
    // console.error(error);
    
  } finally {
  }
}
export async function googleSignUp(navigate, addNewsLetter, setDisabled) {
    setDisabled(true);
    const handleUserCredential = async (userCred) => {

      const user = userCred.user;
      getDoc(doc(db, "users", user.uid)).then(async (document) => {
        if (document.exists()) {
          // alert('You have successfully signed in!')
          navigate('/profile')
          return;
        } else {
          // Step 1: Check if the user already exists
          const userQuery = query(collection(db, "users"), where("email", "==", user.email));
          const querySnapshot = await getDocs(userQuery);
          let oldUserData = null;
          let oldUserId = null;
      
          if (!querySnapshot.empty) {
            // Step 2: Retrieve old user data
            querySnapshot.forEach((doc) => {
              oldUserData = doc.data();
              oldUserId = doc.id;
            });

            // console.log(oldUserData, 'oldUserData')
      
            // Step 3: Delete old user data
            await deleteDoc(doc(db, "users", oldUserId));
          }
          const newUserData = {
            name: user.displayName,
            email: user.email,
            profile_picture: user.photoURL,
            minutes: oldUserData ? oldUserData.minutes : 20, // Use old minutes if available
            ...oldUserData, // Merge old data if available
          };
          await setDoc(doc(db, "users", user.uid), newUserData);

          if(addNewsLetter) {
            addToMailChimp(user.email)
          }
          if(window.fbq) {
              window.fbq('trackCustom', 'GoogleSignUp');
          }

          if(window.gtag) {
              window.gtag('event', 'googleSignUp', {'send_to': 'G-FQXKYSFSR2'})
          }
          setDisabled(false);
          navigate('/profile')
          // alert('You have successfully signed up!')
          return;
        }
      }).catch(async (error) => {
        setDisabled(false);
        alert('There was an error signing up. Please try again.')
      });
    }

    try {
      const userCred = await signInWithPopup(auth, new GoogleAuthProvider());
      handleUserCredential(userCred)
    } catch (error) {
      setDisabled(false);
      // signInWithRedirect(auth, provider)
          
      // .then(handleUserCredential).catch((error) => {
      //     // console.log("Error!",error);
      //     alert('There was an error signing up. Please try again.')
      //     // Handle Errors here.
      //     const errorCode = error.code;
      //     const errorMessage = error.message;
      //     // The email of the user's account used.
      //     const email = error.customData.email;
      //     // The AuthCredential type that was used.
      //     const credential = GoogleAuthProvider.credentialFromError(error);
      //     // ...
      // });
      alert('There was an error signing up. Please try again.')
    }
}

export async function signUpFacebook(navigate, addNewsLetter, setDisabled) {
    setDisabled(true);
    const provider = new FacebookAuthProvider();
    const handleUserCredential = async (userCred) => {
      if(userCred === null) return;
      const user = userCred.user;
      getDoc(doc(db, "users", user.uid)).then(async (document) => {
        if (document.exists()) {
          // alert('You have successfully signed in!')
          navigate('/profile')
          return;
        } else {
          // Step 1: Check if the user already exists
          const userQuery = query(collection(db, "users"), where("email", "==", user.email));
          const querySnapshot = await getDocs(userQuery);
          let oldUserData = null;
          let oldUserId = null;
      
          if (!querySnapshot.empty) {
            // Step 2: Retrieve old user data
            querySnapshot.forEach((doc) => {
              oldUserData = doc.data();
              oldUserId = doc.id;
            });

            // console.log(oldUserData, 'oldUserData')
      
            // Step 3: Delete old user data
            await deleteDoc(doc(db, "users", oldUserId));
          }

          const newUserData = {
            name: user.displayName,
            email: user.email,
            profile_picture: user.photoURL,
            minutes: oldUserData ? oldUserData.minutes : 20, // Use old minutes if available
            ...oldUserData, // Merge old data if available
          };

          await setDoc(doc(db, "users", user.uid), newUserData);

          if(addNewsLetter) {
            addToMailChimp(user.email)
          }
          if(window.fbq) {
              window.fbq('trackCustom', 'FacebookSignUp');
          }

          if(window.gtag) {
              window.gtag('event', 'facebookSignUp', {'send_to': 'G-FQXKYSFSR2'})
          }
          navigate('/profile')
          setDisabled(false);
          // alert('You have successfully signed up!')
          return;
        }
      }).catch(async (error) => {
        alert('There was an error signing up. Please try again.')
        setDisabled(false);
      });
    }
    try {
      const userCred = await signInWithPopup(auth, provider);
      handleUserCredential(userCred)
    } catch (error) {
      setDisabled(false);
      switch(error.code) {
        case 'auth/account-exists-with-different-credential':
          alert('You have already created an account with this email via a different provider. Please sign in with the same email on google or email.')
          break;
        default:
        alert('There was an error signing up. Please try again.')
        break;
      }
    }
}

// export function signUpEmail(email, password, navigate, addNewsLetter) {
//     try {
//       return createUserWithEmailAndPassword(auth, email, password)
//       .then(async (userCredentials) => {
//         await sendEmailVerification(auth.currentUser)
//         const user = userCredentials.user;
        
//         if(window.fbq) {
//           window.fbq('trackCustom', 'EmailSignUp');
//         }
//         if(window.gtag) window.gtag('event', 'emailSignUp', {'send_to': 'G-FQXKYSFSR2'})

//         await setDoc(doc(db, "users", user.uid), {
//             name: user.displayName,
//             email: user.email,
//             profile_picture : user.photoURL,
//             minutes: 20,
//         })
//         if(addNewsLetter) {
//           addToMailChimp(user.email)
//         }
//         navigate('/profile')
//       }).catch(error => {   
//         switch(error.code) {
//           case 'auth/email-already-in-use':
//                 alert('You have already created an account with this email.')
//                 break;
//           case 'auth/invalid-email':
//                 alert('Your email is incorrect.')
//                 break;
//         default:
//             alert('There was an error signing up. Please try again.')
//             break;
//         }
//       })
//     }catch(err){
//         alert("Error : ", err);
//     };
//   }

  export async function signUpEmail(email, password, navigate, addNewsLetter, setDisabled) {
    setDisabled(true);
    try {
      return createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredentials) => {
          await sendEmailVerification(auth.currentUser);
          const user = userCredentials.user;

          // Step 1: Check if the user already exists
          const userQuery = query(collection(db, "users"), where("email", "==", email));
          const querySnapshot = await getDocs(userQuery);
          let oldUserData = null;
          let oldUserId = null;
      
          if (!querySnapshot.empty) {
            // Step 2: Retrieve old user data
            querySnapshot.forEach((doc) => {
              oldUserData = doc.data();
              oldUserId = doc.id;
            });

            // console.log(oldUserData, 'oldUserData')
      
            // Step 3: Delete old user data
            await deleteDoc(doc(db, "users", oldUserId));
          }
  
          if (window.fbq) {
            window.fbq('trackCustom', 'EmailSignUp');
          }
          if (window.gtag) window.gtag('event', 'emailSignUp', { 'send_to': 'G-FQXKYSFSR2' });
  
          // Step 5: Use old data to populate the new account
          const newUserData = {
            name: user.displayName,
            email: user.email,
            profile_picture: user.photoURL,
            minutes: oldUserData ? oldUserData.minutes : 20, // Use old minutes if available
            ...oldUserData, // Merge old data if available
          };
  
          await setDoc(doc(db, "users", user.uid), newUserData);
  
          if (addNewsLetter) {
            addToMailChimp(user.email);
          }
          
          navigate('/profile');
          setDisabled(false);
        }).catch(error => {
          setDisabled(false);
          switch (error.code) {
            case 'auth/email-already-in-use':
              alert('You have already created an account with this email.');
              break;
            case 'auth/invalid-email':
              alert('Your email is incorrect.');
              break;
            default:
              alert('There was an error signing up. Please try again.');
              break;
          }
        });
    } catch (err) {
      setDisabled(false);
      alert("Error: ", err);
      console.log(err);
    }
  }

export async function verify(auth){
    return await sendEmailVerification(auth.currentUser)
}
export function signInEmail(email, password, navigate) {
    try {
      return signInWithEmailAndPassword(auth, email, password).then(() => {
        alert('You have successfully signed in!')
        navigate('/profile')
      }).catch(error => {   
        switch(error.code) {
          case 'auth/email-already-in-use':
                alert('You have already created an account with this email.')
                break;
          case 'auth/invalid-email':
                alert('Your email is incorrect.')
                break;
          default:
            alert('There was an error signing in. Please try again.')
            break;
        }
      })
    }catch(err){
        alert("Error : ", err);
    };
  }

  export async function resetPassword(email) {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent!");
    } catch (err) {
      console.log("Error", err);

      switch (err.code) {
        case 'auth/missing-email':
          alert("Please enter an email address.");
          break;
        case "auth/user-not-found":
          alert("You haven't created an account with this email. Please sign up!");
          break;
        case "auth/invalid-email":
          alert("Invalid email");
          break;
        default:
          alert("There was an error resetting your password. Please try again.");
          break;
      }
    }
  }