import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc, updateDoc, addDoc, collection, increment } from "firebase/firestore";
import { AssemblyAI } from 'assemblyai'
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import htmlToDocx from 'html-docx-js/dist/html-docx';
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
// import Checkbox from '@mui/material/Checkbox';
import {auth, db} from '../../firebase';
import { allowedTypes } from "../../utils";
import './audiodropsl.css';

const client = new AssemblyAI({
    apiKey: "8ae9beb244534c0195280eeec5af6377"
  })

const AudioDropSL = ({handleDisabledTab, audioDropSLState, updateAudioDropSLState}) => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(audioDropSLState.selectedFile);
    const [fileName, setFileName] = useState(audioDropSLState.fileName);
    const [dragging, setDragging] = useState(false);
    const [disable, setDisable] = useState(audioDropSLState.disabled);
    const [status, setStatus] = useState(audioDropSLState.status);
    const [numSpeakers, setNumSpeakers] = useState(audioDropSLState.numSpeakers);
    const [fileMinutes, setFileMinutes] = useState(audioDropSLState.file);
    const [selectedOption, setSelectedOption] = useState(audioDropSLState.selectedOption);
    const [selectedOptionLanguage, setSelectedOptionLanguage] = useState(audioDropSLState.selectedOptionLanguage);

    const { t } = useTranslation("global");

    const options = [
        { value: ["text"], label: t('audioDrop.options.textFile') },
        { value: ["pdf"], label: t('audioDrop.options.pdf') },
        { value: ["word"], label: t('audioDrop.options.wordDocument') },
        { value: ["text", "pdf"], label: t('audioDrop.options.textAndPdf') },
        { value: ["text", "word"], label: t('audioDrop.options.textAndWord') },
        { value: ["pdf", "word"], label: t('audioDrop.options.pdfAndWord') },
        { value: ["text", "pdf", "word"], label: t('audioDrop.options.all') },
    ]
    
    const languageOptions = [
        { value: 'en', label: t('audioDrop.languages.en') },
        { value: 'en_au', label: t('audioDrop.languages.en_au') },
        { value: 'en_uk', label: t('audioDrop.languages.en_uk') },
        { value: 'en_us', label: t('audioDrop.languages.en_us') },
        { value: 'es', label: t('audioDrop.languages.es') },
        { value: 'fr', label: t('audioDrop.languages.fr') },
        { value: 'de', label: t('audioDrop.languages.de') },
        { value: 'it', label: t('audioDrop.languages.it') },
        { value: 'pt', label: t('audioDrop.languages.pt') },
        { value: 'nl', label: t('audioDrop.languages.nl') },
        { value: 'fi', label: t('audioDrop.languages.fi') },
        { value: 'pl', label: t('audioDrop.languages.pl') },
        { value: 'ru', label: t('audioDrop.languages.ru') },
        { value: 'tr', label: t('audioDrop.languages.tr') },
        { value: 'hi', label: t('audioDrop.languages.hi') },
        { value: 'zh', label: t('audioDrop.languages.zh') },
        { value: 'ja', label: t('audioDrop.languages.ja') },
        { value: 'ko', label: t('audioDrop.languages.ko') },
        { value: 'vi', label: t('audioDrop.languages.vi') },
    ].sort((a, b) => a.label.localeCompare(b.label));

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragging(false);
        const file = event.dataTransfer.files[0];

        // if (file && allowedTypes.includes(file.type)) {
            setStatus(t('audioDrop.status1'))
            setSelectedFile(file);
            const audio  = new Audio();
            audio.src = URL.createObjectURL(file);
            audio.addEventListener('loadedmetadata', () => {
                setFileMinutes(Math.round(audio.duration / 60));
            });
            setFileName(file.name);
        // } else {
        //     // Handle invalid file type
        //     alert(t('audioDrop.incorrectFileType'));
        // }
    };

    const deleteTranscript = async (id) => {
        const response = await fetch('https://us-central1-sonic-transcript-410518.cloudfunctions.net/deleteTranscript2', {
            mode: 'no-cors',
            method: 'POST', // or 'GET', 'PUT', etc.
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `transcript_id=${encodeURIComponent(id)}`,
        });
        
        if (!response.ok) {
            // throw new Error('Network response was not ok');
        }
    }

    const createDocument = async (transcriptionText, utterances, type, fileName, speakerLabels) => {
        switch (type) {
            case "text":
                const blob = new Blob([transcriptionText], {type: "text/plain;charset=utf-8"});
                saveAs(blob, fileName+".txt");
                break;
            case "pdf":
                const doc = new jsPDF();
                doc.setFontSize(12); // Set the font size to 10 points
                const maxWidth = 180; // Maximum width in points
                const lineHeight = 10; // Line height in points, adjust as needed
                const pageHeight = doc.internal.pageSize.height; // Page height in points
                const lines = doc.splitTextToSize(transcriptionText, maxWidth); // Split the text
                let y = 20; // Initial y position, adjust as needed
                for (let i = 0; i < lines.length; i++) {
                    if (y + lineHeight > pageHeight) {
                        doc.addPage();
                        y = 20; // Reset y position to top of the new page, adjust as needed
                    }
                    doc.text(lines[i], 20, y); // Adjust the x position as needed
                    y += lineHeight;
                }
                const blob2 = doc.output('blob');
                saveAs(blob2, fileName+".pdf");
                break;
            case "word":
                // Convert the text to HTML
                var html
                if(speakerLabels) {
                    html = utterances.map(utterance => { return `<p>${t("audioDropSL.speaker")} ${utterance.speaker}: ${utterance.text}</p>` }).join('');
                } else {
                    html = `<p>${transcriptionText}</p>`;
                }
 
                // Convert the HTML to a Word document
                const docx = htmlToDocx.asBlob(html);
                saveAs(docx, fileName+'.docx');
                break;
            default:
                break;
        }
    }

    const handleSelectChange = option => {
        if (window.fbq) {
            window.fbq('trackCustom', 'FileType', {content_name: option.label});
        }
        setSelectedOption(option);
    };

    const handleSelectLanguageChange = option => {
        if (window.fbq) {
            window.fbq('trackCustom', 'LanguageTypeSpeakerLabels', {content_name: option.label});
        }
        setSelectedOptionLanguage(option);
    };

    const handleUpload = async () => {
        if (auth.currentUser == null) {
            alert(t('audioDrop.signInAlert'));
            navigate('/signup')
            return;
        }
        // if there is a transcription in progress, get the transcript id
        if(!auth.currentUser.emailVerified){
            alert(t('audioDrop.verifyEmail'));
            navigate('/profile')
            return;
        };
       
        if (selectedFile) {
            const uid = auth.currentUser.uid;
            const audio = new Audio();
            audio.src = URL.createObjectURL(selectedFile);
            audio.addEventListener('loadedmetadata', async () => {
                const duration = audio.duration;
                // Check if user has enough minutes
                const userDoc = await getDoc(doc(db, "users", uid));
                const userData = userDoc.data();
                const corporation = userData.corporation;
                let userMinutes = userData.minutes;
                if(corporation) {
                    const corpDoc = await getDoc(doc(db, "corporations", corporation));
                    userMinutes = corpDoc.data().minutes;
                }

                var fileMinutesNow = Math.round(duration / 60);
                setFileMinutes(fileMinutesNow);

                if (userMinutes < fileMinutesNow) {
                    alert(t('audioDrop.notEnoughMinutes'));
                    navigate('/profile');
                    return;
                }
                if(selectedOption === null) {
                    alert(t('audioDrop.fileType'));
                    return;
                }


                const FILE_PATH = selectedFile;
                var data;
                // checks which model the user wants to use depending on the checkboxes
                if(selectedOptionLanguage.value === undefined || selectedOptionLanguage.value === null) {
                    data = {
                        audio: FILE_PATH,
                        speaker_labels: true,
                        language_detection: true,
                        speakers_expected: numSpeakers === "" ? 2 : numSpeakers,
                    }
                } else {
                    data = {
                        audio: FILE_PATH,
                        speaker_labels: true,
                        language_code: selectedOptionLanguage.value,
                        speakers_expected: numSpeakers === "" ? 2 : numSpeakers,
                    }
                }
                if(window.fbq) {
                    window.fbq('trackCustom', 'UploadedFile');
                }

                setStatus(t('audioDrop.status2'))
                setDisable(true);
                handleDisabledTab(true);
                let transcript = await client.transcripts.submit(data);

                // create an interval to check the status of the transcript
                secureLocalStorage.setItem("transcriptInfoSL", JSON.stringify({
                    id: transcript.id,
                    userMinutes: userMinutes,
                    fileMinutes: fileMinutesNow,
                    fileName: fileName,
                    corporation: corporation
                }));

                const interval = setInterval(async () => {
                    const updatedTranscript = await client.transcripts.get(transcript.id);
                    switch (updatedTranscript.status) {
                        case 'queued':
                            setStatus(t('audioDrop.status4'))
                            break;
                        case 'processing':
                            setStatus(t('audioDrop.status3'))
                            break;
                        case 'completed':
                            setStatus(t('audioDrop.status5'))
                            clearInterval(interval);
                            var transcriptionText = '';
                            
                            for (const utterance of updatedTranscript.utterances) {
                                const utteranceText = "Speaker: "+utterance.speaker + ": " + utterance.text + "\n";
                                transcriptionText += utteranceText;
                            }
                            const fileName = selectedFile.name.split('.')[0];
                            if(corporation) {
                                await addDoc(collection(db, "corporations", corporation, "log"), {
                                    fileName: fileName,
                                    fileType: selectedOption.value,
                                    date: new Date(),
                                    fileMinutes: fileMinutes,
                                    totalMinutes: userMinutes,
                                    language: updatedTranscript.language_code,
                                    email: auth.currentUser.email,
                                    serviceType: 'transcription',
                                });
                                await updateDoc(doc(db, "corporations", corporation), {
                                    minutes: increment(-fileMinutesNow),
                                });
                            } else {
                                await addDoc(collection(db, "users", uid, "log"), {
                                    fileName: fileName,
                                    fileType: selectedOption.value,
                                    date: new Date(),
                                    fileMinutes: fileMinutes,
                                    totalMinutes: userMinutes,
                                    language: updatedTranscript.language_code,
                                    serviceType: 'transcription',
                                });
                                await updateDoc(doc(db, "users", uid), {
                                    minutes: increment(-fileMinutesNow),
                                });
                            }
                            
                            selectedOption.value.forEach(type => {
                                createDocument(transcriptionText, updatedTranscript.utterances, type, fileName, true);
                            });
                            deleteTranscript(transcript.id);
                            secureLocalStorage.removeItem("transcriptInfoSL");
                            setDisable(false);
                            handleDisabledTab(false);
                            break;
                        case 'error':
                            setStatus(t('audioDrop.status6'))
                            clearInterval(interval);
                            // deleteTranscript(transcript.id);
                            // await client.transcripts.delete(transcript.id);
                            // secureLocalStorage.removeItem("transcriptInfoSL");
                            setDisable(false);
                            handleDisabledTab(false);
                            break;
                        default:
                            setStatus(t('audioDrop.status7'))
                            break;
                    }
                }, 5000); // Check the status every 5 seconds
  
        });
    };
    };

    const handleSelectFile = (event) => {
        event.preventDefault();
        const file = event.target.files[0];

        // if (file && allowedTypes.includes(file.type)) {
            setStatus(t('audioDrop.status1'))
            setSelectedFile(file);
            setFileName(file.name);
            const audio  = new Audio();
            audio.src = URL.createObjectURL(file);
            audio.addEventListener('loadedmetadata', () => {
                setFileMinutes(Math.round(audio.duration / 60));
            });
        // } else {
        //     // Handle invalid file type
        //     alert(t('audioDrop.incorrectFileType'));
        // }
        // setSelectedFile(file);
        // setStatus("");
    };

    const handleNumSpeakers = (event) => {
        event.preventDefault();
        if(event.target.value === "") {
            setNumSpeakers("");
            return;
        }
        const num = Math.floor(event.target.value);
        
        if(num < 2) {
           setNumSpeakers(2);
           return;
        } 
        if(num > 10) {
            setNumSpeakers(10);
            return;
        }
        setNumSpeakers(num);
    }

    const handleReset = () => {
        setSelectedFile(null);
        setSelectedOption(null);
        setSelectedOptionLanguage(null);
        setFileName("");
        setStatus("");
        setNumSpeakers(2);
        setFileMinutes(0);
    }

    useEffect(() => {
        const info = JSON.parse(secureLocalStorage.getItem("transcriptInfoSL"));
        
        if (info != null) {
            setDisable(true);
            handleDisabledTab(true);
            const id = info.id;
            const userMinutes = info.userMinutes;
            const fileMinutes = info.fileMinutes;  
            const fileName = info.fileName.split('.')[0];
            const corporation = info.corporation;

            setFileName(fileName);
            setStatus("Loading Status...")
            const interval = setInterval(async () => {
                const updatedTranscript = await client.transcripts.get(id);
                switch (updatedTranscript.status) {
                    case 'queued':
                        setStatus(t('audioDrop.status4'))
                        break;
                    case 'processing':
                        setStatus(t('audioDrop.status3'))
                        break;
                    case 'completed':
                        setStatus(t('audioDrop.status5'))
                        setDisable(false);
                        handleDisabledTab(false);
                        secureLocalStorage.removeItem("transcriptInfoSL");
                        clearInterval(interval);
                        var transcriptionText = '';
                        for (const utterance of updatedTranscript.utterances) {
                            const utteranceText = "Speaker: "+utterance.speaker + ": " + utterance.text + "\n";
                            transcriptionText += utteranceText;
                        }
                        const fileName = selectedFile.name.split('.')[0];
                        if(corporation) {
                            await addDoc(collection(db, "corporations", corporation, "log"), {
                                fileName: fileName,
                                fileType: selectedOption.value,
                                date: new Date(),
                                fileMinutes: fileMinutes,
                                totalMinutes: userMinutes,
                                language: updatedTranscript.language_code,
                                email: auth.currentUser.email,
                                serviceType: 'transcription',
                            });
                            await updateDoc(doc(db, "corporations", corporation), {
                                minutes: increment(-fileMinutes),
                            });
                        } else {
                            await addDoc(collection(db, "users", auth.currentUser.uid, "log"), {
                                fileName: fileName,
                                fileType: selectedOption.value,
                                date: new Date(),
                                fileMinutes: fileMinutes,
                                totalMinutes: userMinutes,
                                language: updatedTranscript.language_code,
                                serviceType: 'transcription',
                            });
                            await updateDoc(doc(db, "users", auth.currentUser.uid), {
                                minutes: increment(-fileMinutes),
                            });
                        }
                        selectedOption.value.forEach(type => {
                            createDocument(transcriptionText, updatedTranscript.utterances, type, fileName, true);
                        });
                        
                        deleteTranscript(id);
                        break;
                    case 'error':
                        setStatus(t('audioDrop.status6'))
                        // deleteTranscript(id);
                        // secureLocalStorage.removeItem("transcriptInfoSL");
                        clearInterval(interval);
                        // await client.transcripts.delete(id);
                        setDisable(false);
                        handleDisabledTab(false);
                        break;
                    default:
                        setStatus(t('audioDrop.status7'))
                        break;
                }
            }, 5000);
        }
    }, [selectedOption, selectedOptionLanguage, selectedFile]);   
    
    const [timer, setTimer] = useState(0);
    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
   
    useEffect(() => {
        let interval;
        if (status === t('audioDrop.status3') || status === t('audioDrop.status4')) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }
        if(status === t('audioDrop.status5')) {
            setTimer(0);
        }
        return () => {
            clearInterval(interval);
        };
    }, [status, t]);

    useEffect(() => {
        updateAudioDropSLState({
            selectedFile: selectedFile,
            fileName: fileName,
            disabled: disable,
            status: status,
            numSpeakers: numSpeakers,
            file: fileMinutes,
            selectedOption: selectedOption,
            selectedOptionLanguage: selectedOptionLanguage
        });
    }, [selectedFile, fileName, disable, status, numSpeakers, fileMinutes, selectedOption, selectedOptionLanguage]);

    return (
        <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className='container'
            style={{ border: dragging ? '2px solid blue' : '2px solid gray'}}
        >
            {selectedFile || fileName ? (
                <div className='dragging'>
                    <p className='status'>{t('audioDrop.status')}<strong>{status}</strong></p>
                    <p>{t('audioDrop.selectedFile')}<strong>{fileName}</strong></p>
                    <p>{t('audioDrop.fileMinutes')}<strong>{fileMinutes}</strong></p>
                    <p><strong>{t('audioDrop.note')}</strong>{t('audioDrop.noteText')}</p>
                    <Select
                        value={selectedOption}
                        onChange={handleSelectChange}
                        options={options}
                        defaultValue={options[0]}
                        styles={{
                            option: (provided) => ({
                              ...provided,
                              textAlign: 'center'
                            }),
                            control: (provided) => ({
                              ...provided,
                              maxHeight: '50px',
                              marginTop: '10px',
                              zIndex: 1000,
                            }),
                          }}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                        placeholder={t('audioDrop.placeHolderFiles')}
                    />
                        {/* <p><strong>Warning:</strong> Not choosing a language will default to our auto detection which doesn't cover all languages!</p> */}
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                            
                            <label>{t('audioDropSL.numSpeakerLabel')}</label>
                            <input
                                className='numSpeakers'
                                type="number"
                                min={2}
                                max={10}
                                placeholder={t('audioDropSL.numSpeaker')}
                                value={numSpeakers}
                                onChange={handleNumSpeakers}
                                style={{
                                    textAlign: 'center',
                                }}
                            />
                        </div>
                        <div style={{marginTop: 10}}>
                            <p><strong>{t('audioDropSL.speakerLabelWarning')}</strong>{t('audioDropSL.speakerLabelWarningText')}</p>
                            <Select
                                value={selectedOptionLanguage}
                                onChange={handleSelectLanguageChange}
                                options={languageOptions}
                                defaultValue={languageOptions[0]}
                                styles={{
                                    option: (provided) => ({
                                        ...provided,
                                        textAlign: 'center'
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        maxHeight: '50px',
                                        marginTop: '10px',
                                        zIndex: 2000,
                                    }),
                                }}
                                menuPortalTarget={document.body}
                                menuPosition='fixed'
                                placeholder={t('audioDrop.selectLanguagePlaceholder')}
                            />
                        </div>
                    
                    <div>
                        {(status === t('audioDrop.status3') || status === t('audioDrop.status4'))&& (
                            <p>Processing Time: <strong>{formatTime(timer)}</strong></p>
                        )}
                    </div>
                    <div>
                        <button className={"submitButtonSL"} onClick={handleUpload} disabled={disable}>{t('audioDrop.send')}</button>
                        {status === t('audioDrop.status5') && (
                            <button className={"submitButtonSL"} style={{marginLeft: 10}} onClick={handleReset}>{t('audioDrop.reset')}</button>
                        )}
                    </div>
                </div>
            ) : (
                <div className='insideUploadBox'>
                    <h2>{t('audioDrop.uploadTitle')}</h2>
                    <p>{t('audioDrop.uploadText')}</p>
                    <button className="submitButtonSL" onClick={() => document.getElementById('fileInput').click()}>{t('audioDrop.selectFile')}</button>
                    <input type="file" id="fileInput" accept={allowedTypes.join(',')} style={{ display: 'none' }} onChange={handleSelectFile} />
                </div>
            )}
        </div>
    );

};

export default AudioDropSL;