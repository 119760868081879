import React, { useEffect, useState, useRef } from 'react';
import { doc, collection, query, orderBy, startAfter, limit, getDocs, getDoc } from 'firebase/firestore';
import jsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';
import { db, auth } from "../../firebase" // Adjust this import according to your firebase config file's location
import "./loglist.css";

function LogList() {
  
  const [originalLogs, setOriginalLogs] = useState([]);
  const [logs, setLogs] = useState([]);
  const [corporateEmails, setCorporateEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [lastVisible, setLastVisible] = useState(null);
  const lastVisibleRef = useRef(null);
  // Create a ref for the logsContainer
  const [allLogsFetched, setAllLogsFetched] = useState(false);
  const fetchedLogIds = useRef(new Set());
  const logsContainerRef = useRef(null);
  const [selectedMonth, setSelectedMonth] = useState(null); // null means no month is selected
  const [selectedEmail, setSelectedEmail] = useState(null);

  const { t } = useTranslation("global");

  const languagesMoreOptions = [
    { value: 'en', label: t('audioDrop.languages.en') },
    { value: 'en_au', label: t('audioDrop.languages.en_au') },
    { value: 'en_uk', label: t('audioDrop.languages.en_uk') },
    { value: 'en_us', label: t('audioDrop.languages.en_us') },
    { value: 'es', label: t('audioDrop.languages.es') },
    { value: 'fr', label: t('audioDrop.languages.fr') },
    { value: 'de', label: t('audioDrop.languages.de') },
    { value: 'it', label: t('audioDrop.languages.it') },
    { value: 'pt', label: t('audioDrop.languages.pt') },
    { value: 'nl', label: t('audioDrop.languages.nl') },
    { value: 'af', label: t('audioDrop.languages.af') },
    { value: 'sq', label: t('audioDrop.languages.sq') },
    { value: 'am', label: t('audioDrop.languages.am') },
    { value: 'ar', label: t('audioDrop.languages.ar') },
    { value: 'hy', label: t('audioDrop.languages.hy') },
    { value: 'as', label: t('audioDrop.languages.as') },
    { value: 'az', label: t('audioDrop.languages.az') },
    { value: 'ba', label: t('audioDrop.languages.ba') },
    { value: 'eu', label: t('audioDrop.languages.eu') },
    { value: 'be', label: t('audioDrop.languages.be') },
    { value: 'bn', label: t('audioDrop.languages.bn') },
    { value: 'bs', label: t('audioDrop.languages.bs') },
    { value: 'br', label: t('audioDrop.languages.br') },
    { value: 'bg', label: t('audioDrop.languages.bg') },
    { value: 'my', label: t('audioDrop.languages.my') },
    { value: 'ca', label: t('audioDrop.languages.ca') },
    { value: 'zh', label: t('audioDrop.languages.zh') },
    { value: 'hr', label: t('audioDrop.languages.hr') },
    { value: 'cs', label: t('audioDrop.languages.cs') },
    { value: 'da', label: t('audioDrop.languages.da') },
    { value: 'et', label: t('audioDrop.languages.et') },
    { value: 'fo', label: t('audioDrop.languages.fo') },
    { value: 'fi', label: t('audioDrop.languages.fi') },
    { value: 'gl', label: t('audioDrop.languages.gl') },
    { value: 'ka', label: t('audioDrop.languages.ka') },
    { value: 'el', label: t('audioDrop.languages.el') },
    { value: 'gu', label: t('audioDrop.languages.gu') },
    { value: 'ht', label: t('audioDrop.languages.ht') },
    { value: 'ha', label: t('audioDrop.languages.ha') },
    { value: 'haw', label: t('audioDrop.languages.haw') },
    { value: 'he', label: t('audioDrop.languages.he') },
    { value: 'hi', label: t('audioDrop.languages.hi') },
    { value: 'hu', label: t('audioDrop.languages.hu') },
    { value: 'is', label: t('audioDrop.languages.is') },
    { value: 'id', label: t('audioDrop.languages.id') },
    { value: 'ja', label: t('audioDrop.languages.ja') },
    { value: 'jw', label: t('audioDrop.languages.jw') },
    { value: 'kn', label: t('audioDrop.languages.kn') },
    { value: 'kk', label: t('audioDrop.languages.kk') },
    { value: 'km', label: t('audioDrop.languages.km') },
    { value: 'ko', label: t('audioDrop.languages.ko') },
    { value: 'lo', label: t('audioDrop.languages.lo') },
    { value: 'la', label: t('audioDrop.languages.la') },
    { value: 'lv', label: t('audioDrop.languages.lv') },
    { value: 'ln', label: t('audioDrop.languages.ln') },
    { value: 'lt', label: t('audioDrop.languages.lt') },
    { value: 'lb', label: t('audioDrop.languages.lb') },
    { value: 'mk', label: t('audioDrop.languages.mk') },
    { value: 'mg', label: t('audioDrop.languages.mg') },
    { value: 'ms', label: t('audioDrop.languages.ms') },
    { value: 'ml', label: t('audioDrop.languages.ml') },
    { value: 'mt', label: t('audioDrop.languages.mt') },
    { value: 'mi', label: t('audioDrop.languages.mi') },
    { value: 'mr', label: t('audioDrop.languages.mr') },
    { value: 'mn', label: t('audioDrop.languages.mn') },
    { value: 'ne', label: t('audioDrop.languages.ne') },
    { value: 'no', label: t('audioDrop.languages.no') },
    { value: 'nn', label: t('audioDrop.languages.nn') },
    { value: 'oc', label: t('audioDrop.languages.oc') },
    { value: 'pa', label: t('audioDrop.languages.pa') },
    { value: 'ps', label: t('audioDrop.languages.ps') },
    { value: 'fa', label: t('audioDrop.languages.fa') },
    { value: 'pl', label: t('audioDrop.languages.pl') },
    { value: 'ro', label: t('audioDrop.languages.ro') },
    { value: 'ru', label: t('audioDrop.languages.ru') },
    { value: 'sa', label: t('audioDrop.languages.sa') },
    { value: 'sr', label: t('audioDrop.languages.sr') },
    { value: 'sn', label: t('audioDrop.languages.sn') },
    { value: 'sd', label: t('audioDrop.languages.sd') },
    { value: 'si', label: t('audioDrop.languages.si') },
    { value: 'sk', label: t('audioDrop.languages.sk') },
    { value: 'sl', label: t('audioDrop.languages.sl') },
    { value: 'so', label: t('audioDrop.languages.so') },
    { value: 'su', label: t('audioDrop.languages.su') },
    { value: 'sw', label: t('audioDrop.languages.sw') },
    { value: 'sv', label: t('audioDrop.languages.sv') },
    { value: 'tl', label: t('audioDrop.languages.tl') },
    { value: 'tg', label: t('audioDrop.languages.tg') },
    { value: 'ta', label: t('audioDrop.languages.ta') },
    { value: 'tt', label: t('audioDrop.languages.tt') },
    { value: 'te', label: t('audioDrop.languages.te') },
    { value: 'th', label: t('audioDrop.languages.th') },
    { value: 'bo', label: t('audioDrop.languages.bo') },
    { value: 'tr', label: t('audioDrop.languages.tr') },
    { value: 'tk', label: t('audioDrop.languages.tk') },
    { value: 'uk', label: t('audioDrop.languages.uk') },
    { value: 'ur', label: t('audioDrop.languages.ur') },
    { value: 'uz', label: t('audioDrop.languages.uz') },
    { value: 'vi', label: t('audioDrop.languages.vi') },
    { value: 'cy', label: t('audioDrop.languages.cy') },
    { value: 'yi', label: t('audioDrop.languages.yi') },
    { value: 'yo', label: t('audioDrop.languages.yo') },
  ];

  const checkCorporateAccount = async () => {
    const userRef = doc(db, 'users', auth.currentUser.uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const { corporation } = userDoc.data();
      if (corporation) {
        // Assuming 'corporation' is the ID or reference to the corporation document
        const corpRef = doc(db, 'corporations', corporation); // Adjust 'corporations' to your actual collection name
        const corpDoc = await getDoc(corpRef);
        if (corpDoc.exists()) {
          const corpData = corpDoc.data();
          // Assuming 'emails' is the field containing emails in the corporation document
          const corporateEmails = corpData.emails;
          setCorporateEmails(corporateEmails); // Update state or handle emails as needed
          return corporation;
        }
      }
    }
    return null;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchLogs = async () => {
    if (allLogsFetched || loading || !auth.currentUser) return;
    setLoading(true);
  

    let q = query(
      collection(db, "users", auth.currentUser.uid, "log"), 
      orderBy("date", "desc"), 
      limit(2)
    );
    const corporation = await checkCorporateAccount();
    if(corporation) {
      q = query(
        collection(db, "corporations", corporation, "log"),
        orderBy("date", "desc"), 
        limit(2)
      );
    }

    // Apply date range filter if a month is selected
    // if (selectedMonth !== null) {
    //   const currentYear = new Date().getFullYear();
    //   const startDate = new Date(currentYear, selectedMonth, 1);
    //   const endDate = new Date(currentYear, selectedMonth + 1, 1);
    //   q = query(q, where("date", ">=", startDate), where("date", "<", endDate));
    // }

    // console.log('lastVisible:', lastVisible);
    const lastVisible = lastVisibleRef.current;
    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }
  
    const documentSnapshots = await getDocs(q);
    let duplicateFound = false;
  
    const newLogs = documentSnapshots.docs.filter(doc => {
      if (fetchedLogIds.current.has(doc.id)) {
        duplicateFound = true;
        return false; // Exclude this document as it's a duplicate
      }
      fetchedLogIds.current.add(doc.id);
      return true; // Include this document as it's not a duplicate
    }).map(doc => ({ id: doc.id, ...doc.data() }));
  
    if (duplicateFound) {
      setAllLogsFetched(true);
      setLoading(false);
      return; // Stop processing as we found a duplicate
    }
  
    lastVisibleRef.current = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    setOriginalLogs(prevLogs => [...prevLogs, ...newLogs]);
    setLoading(false);
  };

  useEffect(() => {
    setAllLogsFetched(false);
    fetchLogs();
  }, [allLogsFetched]);

  useEffect(() => {
      // Function to handle scroll event
      const handleScroll = () => {
        const logsContainer = logsContainerRef.current;
        // Check if the user has scrolled to the bottom of the logsContainer
        if (logsContainer) {
          // Introduce a tolerance level, e.g., 1 pixel
          const tolerance = 1;
          const isNearBottom = Math.abs(logsContainer.scrollHeight - logsContainer.scrollTop - logsContainer.clientHeight) <= tolerance;
          // console.log('isNearBottom:', isNearBottom, 'allLogsFetched:', allLogsFetched);
          if (isNearBottom && !allLogsFetched) {
            // console.log('Fetching more logs...');
            fetchLogs();
          }
        }
      };

      // Attach the event listener to the logsContainer
      const logsContainer = logsContainerRef.current;
      if (logsContainer) {
        logsContainer.addEventListener('scroll', handleScroll);
      }

      // Cleanup function to remove the event listener
      return () => {
        if (logsContainer) {
          logsContainer.removeEventListener('scroll', handleScroll);
        }
      };
    }, [allLogsFetched, selectedMonth, fetchLogs]); // Empty dependency array means this effect runs once on mount

    useEffect(() => {
      const filtered = originalLogs.filter(log => {
        const logDate = log.date.toDate();
        const currentYear = new Date().getFullYear();
        // Check if the log matches the selected month and year
        const monthMatch = selectedMonth !== null && logDate.getFullYear() === currentYear && logDate.getMonth() === (parseInt(selectedMonth));
        // Check if the log matches the selected email
        const emailMatch = selectedEmail !== null && selectedEmail !== t('logList.selectEmail') && log.email === selectedEmail;
    
        // Apply filters based on what is selected: date, email, or both
        if (selectedMonth !== null && selectedMonth !== '' && selectedEmail !== null && selectedEmail !== '') {
          return monthMatch && emailMatch; // Both filters
        } else if (selectedMonth !== null && selectedMonth !== '') {
          return monthMatch; // Only date filter
        } else if (selectedEmail !== null && selectedEmail !== '') {
          return emailMatch; // Only email filter
        }
        return true; // No filters applied, return all logs
      });
    
      setLogs(filtered);
    }, [selectedMonth, selectedEmail, originalLogs]);

    function getLanguageLabel(languageCode) {
      const language = languagesMoreOptions.find(lang => lang.value === languageCode);
      return language ? language.label : t('logList.unknownLanguage');
    }

    const generatePDF = () => {
      const doc = new jsPDF();
      doc.setFontSize(12); // Set the font size to 12 points
      const pageWidth = doc.internal.pageSize.width; // Page width in points
      const leftMargin = 10;
      const rightMargin = 10;
      const maxWidth = pageWidth - leftMargin - rightMargin; // Adjusted maximum width
      let yCursor = 10; // Initial vertical cursor position
      const lineHeight = 5; // Line height in points, adjust as needed
      const pageHeight = doc.internal.pageSize.height; // Page height in points
    
      logs.forEach(log => {
        let date = log.date.toDate();
        let readableDate = date.toLocaleDateString("en-US", {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
        let totalMinutesAfter = log.totalMinutes - log.fileMinutes;
        let language = getLanguageLabel(log.language);
    
        // For each property, split the text and add it to the PDF
        var properties = [
          `${t('logList.fileName')}: ${log.fileName}`,
          `${t('logList.email')}: ${log.email}`,
          `${t('logList.date')}: ${readableDate}`,
          `${t('logList.language')}: ${language}`,
          `${t('logList.fileType')}: ${log.fileType.join(", ")}`,
          `${t('logList.totalMinutesBefore')}: ${log.totalMinutes}`,
          `${t('logList.totalMinutesAfter')}: ${totalMinutesAfter}`,
          `${t('logList.fileMinutes')}: ${log.fileMinutes}`
        ];

        log.serviceType && properties.splice(2, 0, `${t('logList.serviceType')}: ${log.serviceType}`);
    
        properties.forEach(property => {
          const lines = doc.splitTextToSize(property, maxWidth); // Split the text
          lines.forEach(line => {
            if (yCursor + lineHeight > pageHeight) {
              doc.addPage();
              yCursor = 20; // Reset y position to top of the new page
            }
            doc.text(line, leftMargin, yCursor); // Adjust the x position as needed
            yCursor += lineHeight;
          });
          yCursor += lineHeight; // Add extra space between properties
        });

        // Draw a line to separate each log
        if (yCursor + lineHeight < pageHeight) { // Ensure there's space for the line
          doc.line(leftMargin, yCursor, pageWidth - rightMargin, yCursor);
          yCursor += 2; // Add a small gap after the line to ensure separation
        }
    
        // Add a space between logs
        yCursor += 10;
      });
    
      // Save the PDF
      doc.save('logs.pdf');
    };

  return (
    <div>
      <h2 className='itemTitle'>{t('logList.title')}</h2>
      <div className="center-container">
        <select className='select-style' onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
          <option value="">{t('logList.selectMonth')}</option>
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i} value={i}>{new Date(0, i).toLocaleString('en-US', { month: 'long' })}</option>
          ))}
        </select>
        {corporateEmails && (
          <select className='select-style' onChange={(e) => setSelectedEmail(e.target.value)} value={selectedEmail}>
            <option value="">{t('logList.selectEmail')}</option>
            {corporateEmails.map(email => (
              <option key={email} value={email}>{email}</option>
            ))}
          </select>
        )}
      </div>
      
      <div className="logsContainer" ref={logsContainerRef}>
      
        {logs.map(log => {
          let date = log.date.toDate();
          let readableDate = date.toLocaleDateString("en-US", { // You can adjust the locale and options
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          });
          let totalMinutesAfter = log.totalMinutes - log.fileMinutes;
          let language = getLanguageLabel(log.language);
          let serviceType = log.serviceType ? t('logList.'+log.serviceType) : null;
          return (
            <div key={log.id} className='box'>
              {/* Render your log item here */}
              <div className='itemBold'>{log.fileName}</div>
              <div className='item'><strong>{t('logList.email')}:</strong> {log.email}</div>
              {log.serviceType && <div className='item'><strong>{t('logList.serviceType')}:</strong> {serviceType}</div>}
              <div className='item'><strong>{t('logList.date')}:</strong> {readableDate}</div>
              <div className='item'><strong>{t('logList.fileType')}:</strong> {log.fileType.join(", ")}</div>
              <div className='item'><strong>{t('logList.language')}: </strong>{language}</div>
              <div className='item'><strong>{t('logList.totalMinutesBefore')}: </strong>{log.totalMinutes}</div>
              <div className='item'><strong>{t('logList.totalMinutesAfter')}: </strong>{totalMinutesAfter}</div>
              <div className='item'><strong>{t('logList.fileMinutes')}: </strong>{log.fileMinutes}</div>
            </div>
          )

        })}
        {loading && <h2 className='itemTitle'>{t('logList.loading')}</h2>}
      </div>
      <div className="center-container">
        <button className='submitButton' onClick={generatePDF}>{t('logList.downloadLogs')}</button>
      </div>
    </div>
  );
}

export default LogList;