import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/home/Home'
import Profile from './pages/profile/Profile'
import SignUp from './pages/signup/SignUp'
import PrivacyPolicy from './pages/privacypolicy/PrivacyPolicy'
import FAQ from "./pages/faq/FAQ"
import Pricing from './pages/pricing/Pricing'
import './App.css'

const App = () => {
  return (
      <BrowserRouter>
          <Routes>
              <Route path='/' element={<Home />} />
              <Route path='profile' element={<Profile />} />
              <Route path='signup' element={<SignUp />} />
              <Route path='privacy' element={<PrivacyPolicy />} />
              <Route path='faq' element={<FAQ />} />
              <Route path='pricing' element={<Pricing />} />
          </Routes>
      </BrowserRouter>
  )
}

export default App