import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import './whatGPT3.css'
import { Feature } from '../../components'


const WhatGPT3 = () => {

  const { t } = useTranslation("global");

  const titles = [t('whatGPT3.title1'), t('whatGPT3.title2'), ''];
  const texts = [
    <>{t('whatGPT3.text1')}</>,
    <>{t('whatGPT3.text2p1')}<Link to={'./faq'} style={{color: 'blue', textDecoration: 'underline'}}>{t('whatGPT3.text2p2')}</Link></>,
    <></>
  ]
  const titlesRowTwo = [t('whatGPT3.title3'), t('whatGPT3.title4'), t('whatGPT3.title5')]
  const textsRowTwo = [
  <>{t('whatGPT3.text3')}</>,
  <>{t('whatGPT3.text4p1')}<strong><strong style={{fontSize: 24}}>{t('whatGPT3.text4p2')}</strong>{t('whatGPT3.text4p3')}</strong><strong>{t('whatGPT3.text4p4')}<strong style={{fontSize: 24}}>{t('whatGPT3.text4p5')}</strong>{t('whatGPT3.text4p6')}</strong>{t('whatGPT3.text4p7')}<Link to={'./pricing'} style={{color: 'blue', textDecoration: 'underline'}}>{t('whatGPT3.text4p8')}</Link> </>, 
  <>{t('whatGPT3.text5p1')}<strong>{t('whatGPT3.text5p2')}</strong>{t('whatGPT3.text5p3')}<strong>{t('whatGPT3.text5p4')}</strong> <strong>{t('whatGPT3.text5p5')}</strong>{t('whatGPT3.text5p6')}</>
]

const text = <>{t('whatGPT3.text0p1')}<strong>{t('whatGPT3.text0p2')}</strong>{t('whatGPT3.text0p3')}</>

  return (
    <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
      <div className="gpt3__whatgpt3-feature">
        <Feature title={t('whatGPT3.title0')} text={text} />
      </div>
      <div className="gpt3__whatgpt3-container">
        {titlesRowTwo.map((title, index) => {
          return <Feature title={title} text={textsRowTwo[index]} />
        })}
      </div>
      <div className="gpt3__whatgpt3-feature">
        <Feature title={t('whatGPT3.title6')} 
          text={
            <>
              <p>{t('whatGPT3.text6p1')}<Link to={'./faq'} style={{color: 'blue', textDecoration: 'underline'}}>{t('whatGPT3.text6p2')}</Link>{t('whatGPT3.text6p3')}</p>
              <br />
              <p><strong>{t('whatGPT3.text6p4')}</strong></p>
            </>
          }
         />
      </div>
      <div className="gpt3__whatgpt3-container">
        {titles.map((title, index) => {
          return <Feature title={title} text={texts[index]} />
        })}
      </div>
    </div>
  )
}

export default WhatGPT3