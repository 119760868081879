import React from 'react'
import { useTranslation } from 'react-i18next';
import "./faq.css"
function HowMinutesWork() {

  const { t } = useTranslation("global");
  const howMinutesWork = 'FAQ.howMinutesWork.description'

  return (
    <div>
        <p className='faqText'>
         {t(howMinutesWork)}
        </p>
    </div>
  )
}

export default HowMinutesWork