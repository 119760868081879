import React from 'react'
import { useTranslation } from 'react-i18next';
// import {languages} from '../../utils'
import './languages.css' 
// acceptable audio formats

const Languages = () => {

    const { t } = useTranslation("global");

    const languageSpeakerLabels = [
        t('audioDrop.languages.nl'),
        t('audioDrop.languages.fi'),
        t('audioDrop.languages.fr'),
        t('audioDrop.languages.de'),
        t('audioDrop.languages.en'),
        t('audioDrop.languages.it'),
        t('audioDrop.languages.pl'),
        t('audioDrop.languages.pt'),
        t('audioDrop.languages.ru'),
        t('audioDrop.languages.es'),
        t('audioDrop.languages.tr'),
        t('audioDrop.languages.en_us'),
        t('audioDrop.languages.en_au'),
        t('audioDrop.languages.en_uk'),
        t('audioDrop.languages.zh'),
        t('audioDrop.languages.hi')
    ].sort();

      // const languageNoSpeakerLabels = [
      //   "Chinese",
      //   "Dutch",
      //   "Finnish",
      //   "French",
      //   "German",
      //   "Global English",
      //   "Hindi",
      //   "Italian",
      //   "Japanese",
      //   "Korean",
      //   "Polish",
      //   "Portuguese",
      //   "Russian",
      //   "Spanish",
      //   "Turkish",
      //   "US English",
      //   "Ukrainian",
      //   "Vietnamese",
      //   "Australian English",
      //   "British English"
      // ].sort()
      const languageNoSpeakerLabels = [
        t('audioDrop.languages.zh'),
        t('audioDrop.languages.nl'),
        t('audioDrop.languages.fi'),
        t('audioDrop.languages.fr'),
        t('audioDrop.languages.de'),
        t('audioDrop.languages.en'),
        t('audioDrop.languages.hi'),
        t('audioDrop.languages.it'),
        t('audioDrop.languages.ja'),
        t('audioDrop.languages.ko'),
        t('audioDrop.languages.pl'),
        t('audioDrop.languages.pt'),
        t('audioDrop.languages.ru'),
        t('audioDrop.languages.es'),
        t('audioDrop.languages.tr'),
        t('audioDrop.languages.en_us'),
        t('audioDrop.languages.uk'),
        t('audioDrop.languages.vi'),
        t('audioDrop.languages.en_au'),
        t('audioDrop.languages.en_uk')
    ].sort();

    //  const audioDrop.languages = [
    //     "Global English",
    //     "Australian English",
    //     "British English",
    //     "American English",
    //     "Spanish",
    //     "French",
    //     "German",
    //     "Italian",
    //     "Portuguese",
    //     "Dutch",
    //     "Afrikaans",
    //     "Albanian",
    //     "Amharic",
    //     "Arabic",
    //     "Armenian",
    //     "Assamese",
    //     "Azerbaijani",
    //     "Bashkir",
    //     "Basque",
    //     "Belarusian",
    //     "Bengali",
    //     "Bosnian",
    //     "Breton",
    //     "Bulgarian",
    //     "Burmese",
    //     "Catalan",
    //     "Chinese",
    //     "Croatian",
    //     "Czech",
    //     "Danish",
    //     "Estonian",
    //     "Faroese",
    //     "Finnish",
    //     "Galician",
    //     "Georgian",
    //     "Greek",
    //     "Gujarati",
    //     "Haitian",
    //     "Hausa",
    //     "Hawaiian",
    //     "Hebrew",
    //     "Hindi",
    //     "Hungarian",
    //     "Icelandic",
    //     "Indonesian",
    //     "Japanese",
    //     "Javanese",
    //     "Kannada",
    //     "Kazakh",
    //     "Khmer",
    //     "Korean",
    //     "Lao",
    //     "Latin",
    //     "Latvian",
    //     "Lingala",
    //     "Lithuanian",
    //     "Luxembourgish",
    //     "Macedonian",
    //     "Malagasy",
    //     "Malay",
    //     "Malayalam",
    //     "Maltese",
    //     "Maori",
    //     "Marathi",
    //     "Mongolian",
    //     "Nepali",
    //     "Norwegian",
    //     "Norwegian Nynorsk",
    //     "Occitan",
    //     "Panjabi",
    //     "Pashto",
    //     "Persian",
    //     "Polish",
    //     "Romanian",
    //     "Russian",
    //     "Sanskrit",
    //     "Serbian",
    //     "Shona",
    //     "Sindhi",
    //     "Sinhala",
    //     "Slovak",
    //     "Slovenian",
    //     "Somali",
    //     "Sundanese",
    //     "Swahili",
    //     "Swedish",
    //     "Tagalog",
    //     "Tajik",
    //     "Tamil",
    //     "Tatar",
    //     "Telugu",
    //     "Thai",
    //     "Tibetan",
    //     "Turkish",
    //     "Turkmen",
    //     "Ukrainian",
    //     "Urdu",
    //     "Uzbek",
    //     "Vietnamese",
    //     "Welsh",
    //     "Yiddish",
    //     "Yoruba",
    //   ];

    const languages = [
      t('audioDrop.languages.en'),
      t('audioDrop.languages.en_au'),
      t('audioDrop.languages.en_uk'),
      t('audioDrop.languages.en_us'),
      t('audioDrop.languages.es'),
      t('audioDrop.languages.fr'),
      t('audioDrop.languages.de'),
      t('audioDrop.languages.it'),
      t('audioDrop.languages.pt'),
      t('audioDrop.languages.nl'),
      t('audioDrop.languages.af'),
      t('audioDrop.languages.sq'),
      t('audioDrop.languages.am'),
      t('audioDrop.languages.ar'),
      t('audioDrop.languages.hy'),
      t('audioDrop.languages.as'),
      t('audioDrop.languages.az'),
      t('audioDrop.languages.ba'),
      t('audioDrop.languages.eu'),
      t('audioDrop.languages.be'),
      t('audioDrop.languages.bn'),
      t('audioDrop.languages.bs'),
      t('audioDrop.languages.br'),
      t('audioDrop.languages.bg'),
      t('audioDrop.languages.my'),
      t('audioDrop.languages.ca'),
      t('audioDrop.languages.zh'),
      t('audioDrop.languages.hr'),
      t('audioDrop.languages.cs'),
      t('audioDrop.languages.da'),
      t('audioDrop.languages.et'),
      t('audioDrop.languages.fo'),
      t('audioDrop.languages.fi'),
      t('audioDrop.languages.gl'),
      t('audioDrop.languages.ka'),
      t('audioDrop.languages.el'),
      t('audioDrop.languages.gu'),
      t('audioDrop.languages.ht'),
      t('audioDrop.languages.ha'),
      t('audioDrop.languages.haw'),
      t('audioDrop.languages.he'),
      t('audioDrop.languages.hi'),
      t('audioDrop.languages.hu'),
      t('audioDrop.languages.is'),
      t('audioDrop.languages.id'),
      t('audioDrop.languages.ja'),
      t('audioDrop.languages.jw'),
      t('audioDrop.languages.kn'),
      t('audioDrop.languages.kk'),
      t('audioDrop.languages.km'),
      t('audioDrop.languages.ko'),
      t('audioDrop.languages.lo'),
      t('audioDrop.languages.la'),
      t('audioDrop.languages.lv'),
      t('audioDrop.languages.ln'),
      t('audioDrop.languages.lt'),
      t('audioDrop.languages.lb'),
      t('audioDrop.languages.mk'),
      t('audioDrop.languages.mg'),
      t('audioDrop.languages.ms'),
      t('audioDrop.languages.ml'),
      t('audioDrop.languages.mt'),
      t('audioDrop.languages.mi'),
      t('audioDrop.languages.mr'),
      t('audioDrop.languages.mn'),
      t('audioDrop.languages.ne'),
      t('audioDrop.languages.no'),
      t('audioDrop.languages.nn'),
      t('audioDrop.languages.oc'),
      t('audioDrop.languages.pa'),
      t('audioDrop.languages.ps'),
      t('audioDrop.languages.fa'),
      t('audioDrop.languages.pl'),
      t('audioDrop.languages.ro'),
      t('audioDrop.languages.ru'),
      t('audioDrop.languages.sa'),
      t('audioDrop.languages.sr'),
      t('audioDrop.languages.sn'),
      t('audioDrop.languages.sd'),
      t('audioDrop.languages.si'),
      t('audioDrop.languages.sk'),
      t('audioDrop.languages.sl'),
      t('audioDrop.languages.so'),
      t('audioDrop.languages.su'),
      t('audioDrop.languages.sw'),
      t('audioDrop.languages.sv'),
      t('audioDrop.languages.tl'),
      t('audioDrop.languages.tg'),
      t('audioDrop.languages.ta'),
      t('audioDrop.languages.tt'),
      t('audioDrop.languages.te'),
      t('audioDrop.languages.th'),
      t('audioDrop.languages.bo'),
      t('audioDrop.languages.tr'),
      t('audioDrop.languages.tk'),
      t('audioDrop.languages.uk'),
      t('audioDrop.languages.ur'),
      t('audioDrop.languages.uz'),
      t('audioDrop.languages.vi'),
      t('audioDrop.languages.cy'),
      t('audioDrop.languages.yi'),
      t('audioDrop.languages.yo')
  ];

  return (
    <div>
      <h1>{t('languages.title')}</h1>
      <div className='aaf-container'>
        <div>
          <h3>{t('languages.titleSpeakerLabels')}</h3>
          <ol className="audio-list">
            {languageSpeakerLabels.map((lang) => (
              <li key={lang}>{lang}</li>
            ))}
          </ol>
        </div>
        <div>
          <h3>{t('languages.titleWithoutSpeakerLabels')}</h3>
          <ol className="video-list">
            {languageNoSpeakerLabels.map((lang) => (
              <li key={lang}>{lang}</li>
            ))}
          </ol>
        </div>
        <div>
          <h3>{t('languages.moreOptions')}</h3>
          <ol className="video-list">
            {languages.sort().map((lang) => (
              <li key={lang}>{lang}</li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};



export default Languages