export const allowedTypes = [
    'audio/mp3', 'audio/mp4', 'audio/mpeg', 'audio/mpga', 'audio/m4a', 'audio/wav', 'video/mp4',
    'audio/3ga', 'video/webm', 'audio/8svx', 'video/mts', 'video/m2ts', 'video/ts',
    'audio/aac', 'video/mov', 'audio/ac3', 'audio/mp2',
    'audio/aif', 'video/mp4', 'video/m4p', 'video/m4v',
    'audio/aiff', 'video/mxf', 'audio/alac',
    'audio/amr', 'audio/ape', 'audio/au', 'audio/dss', 'audio/flac', 'video/flv',
    'audio/m4a', 'audio/m4b', 'audio/m4p', 'audio/m4r', 'audio/mp3', 'audio/mpga',
    'audio/ogg', 'audio/oga', 'audio/mogg', 'audio/opus', 'audio/qcp', 'audio/tta',
    'audio/voc', 'audio/wav', 'audio/wma', 'audio/wv', 'audio/3ga', 'video/webm',
    'audio/8svx', 'video/mts', 'video/m2ts', 'video/ts', 'audio/aac', 'video/mov',
    'audio/ac3', 'audio/mp2', 'audio/aif', 'video/mp4', 'video/m4p', 'video/m4v',
    'audio/aiff', 'video/mxf', 'audio/alac', 'audio/amr', 'audio/ape', 'audio/au',
    'audio/dss', 'audio/flac', 'video/flv', 'audio/m4a', 'audio/m4b', 'audio/m4p',
    'audio/m4r', 'audio/mp3', 'audio/mpga', 'audio/ogg', 'audio/oga', 'audio/mogg',
    'audio/opus', 'audio/qcp',
];

export const languages = [
    "Global English",
    "Australian English",
    "British English",
    "American English",
    "Spanish",
    "French",
    "German",
    "Italian",
    "Portuguese",
    "Dutch",
    "Afrikaans",
    "Albanian",
    "Amharic",
    "Arabic",
    "Armenian",
    "Assamese",
    "Azerbaijani",
    "Bashkir",
    "Basque",
    "Belarusian",
    "Bengali",
    "Bosnian",
    "Breton",
    "Bulgarian",
    "Burmese",
    "Catalan",
    "Chinese",
    "Croatian",
    "Czech",
    "Danish",
    "Estonian",
    "Faroese",
    "Finnish",
    "Galician",
    "Georgian",
    "Greek",
    "Gujarati",
    "Haitian",
    "Hausa",
    "Hawaiian",
    "Hebrew",
    "Hindi",
    "Hungarian",
    "Icelandic",
    "Indonesian",
    "Japanese",
    "Javanese",
    "Kannada",
    "Kazakh",
    "Khmer",
    "Korean",
    "Lao",
    "Latin",
    "Latvian",
    "Lingala",
    "Lithuanian",
    "Luxembourgish",
    "Macedonian",
    "Malagasy",
    "Malay",
    "Malayalam",
    "Maltese",
    "Maori",
    "Marathi",
    "Mongolian",
    "Nepali",
    "Norwegian",
    "Norwegian Nynorsk",
    "Occitan",
    "Panjabi",
    "Pashto",
    "Persian",
    "Polish",
    "Romanian",
    "Russian",
    "Sanskrit",
    "Serbian",
    "Shona",
    "Sindhi",
    "Sinhala",
    "Slovak",
    "Slovenian",
    "Somali",
    "Sundanese",
    "Swahili",
    "Swedish",
    "Tagalog",
    "Tajik",
    "Tamil",
    "Tatar",
    "Telugu",
    "Thai",
    "Tibetan",
    "Turkish",
    "Turkmen",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Vietnamese",
    "Welsh",
    "Yiddish",
    "Yoruba",
  ];