import React, {useState, useEffect} from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { onAuthStateChanged } from "firebase/auth";
import {doc, onSnapshot } from 'firebase/firestore';
import { CgProfile } from "react-icons/cg";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import {db, auth} from '../../firebase';
import logo from "../../assets/stLogo.png"
import './navbar.css'

const LanguageChanger = ({}) => {
  const [t, i18next] = useTranslation("global");
  const [selectedOption, setSelectedOption] = useState("");
  const handleSelectChange = option => {
    i18next.changeLanguage(option.value[0]).then(() => {
      // setSelectedOption(option);
    });
    // setSelectedOption(option);
  };

  const options = [
      { value: ["en"], label: t('navbar.english') },
      { value: ["es"], label: t('navbar.spanish') },
      { value: ["pt"], label: t('navbar.portuguese') },
      { value: ["hi"], label: t('navbar.hindi') },
  ]

  useEffect(() => {
    const currentLanguage = i18next.language;
    const currentOption = options.find(option => option.value[0] === currentLanguage);
    setSelectedOption(currentOption);
  }, [i18next.language]);

  return (
    <div className='gpt3__navbar-language'>
      <Select
        value={selectedOption}
        onChange={handleSelectChange}
        options={options}
        defaultValue={options[0]}
        styles={{
            option: (provided) => ({
              ...provided,
              textAlign: 'center'
            }),
            control: (provided) => ({
              ...provided,
              // maxHeight: '50px',
              // marginTop: '10px',
              zIndex: 1000,
            }),
          }}
        menuPortalTarget={document.body}
        menuPosition='fixed'
        placeholder={t('navbar.languages')}
      />
    </div>
  )
}

const Menu = ({userSignedIn}) => {
  const [t] = useTranslation("global");
  return (
    <>
      <p><Link to={'/'}>{t("navbar.link1")}</Link></p>
      <p><Link to={'/privacy'}>{t("navbar.link2")}</Link></p>
      <p><Link to={'/faq'}>{t("navbar.link3")}</Link></p>
      <p><Link to={'/pricing'}>{t("navbar.link4")}</Link></p>
      {userSignedIn && (<p><Link to={'/profile'}>{t("navbar.link5")}</Link></p>)}
      <><LanguageChanger /></>
    </>
  )
}

const SignUpIn = ({userSignedIn, setUserSignedIn}) => {
  
  const [minutes, setMinutes] = useState(0);
  const [t, i18next] = useTranslation("global");
  // console.log(t, i18next, 't, i18next')
  
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserSignedIn(true);
        try {
          // console.log(auth.currentUser, 'auth')
          // if(auth.currentUser.uid == null) return;
          const userRef = doc(db, 'users', user.uid); // Replace with the actual user ID
          const unsubscribe = onSnapshot(userRef, (userData) => {
            if (userData.exists()) {
              const { minutes, corporation } = userData.data();
              if (corporation) {
                // Assuming `db` is your Firestore instance and `corporations` is the name of your collection
                const corpRef = doc(db, "corporations", corporation);
                const unsubscribeCorp = onSnapshot(corpRef, (corpDoc) => {
                  if (corpDoc.exists()) {
                    const corpData = corpDoc.data();
                    // Assuming the corporation document has a `minutes` field
                    const corporationMinutes = corpData.minutes;
                    // Now you can use `corporationMinutes` as needed
                    setMinutes(corporationMinutes);
                    // If you need to update the state or perform any action with the corporation minutes, do it here
                  } else {
                    // console.log("No such corporation document!");
                  }
                }, (error) => {
                  console.error(t("navbar.error1"), error);
                });

                // Remember to unsubscribe from both user and corporation documents when the component unmounts or the user changes
                return () => {
                  unsubscribe(); // Unsubscribe from user document
                  unsubscribeCorp(); // Unsubscribe from corporation document
                };
              } else {
                setMinutes(minutes);
              }
            }
          }, (error) => {
            console.error(t("navbar.error2"), error);
          });
          return () => unsubscribe();
        } catch (error) {
          console.error(t("navbar.error2"), error);
        }
        // ...
      } else {
        // User is signed out
        setUserSignedIn(false);
        // ...
      }
    });
  }, [setUserSignedIn]);


  return (
    <>
      {userSignedIn ? (
        <Link to={'/profile'} className='gpt3__navbar-sign_profile'>
            <p>
              {t("navbar.userSignedIn")}{minutes}
            </p>
            <CgProfile color='#000' size={50} />
        </Link>
        ):(
          <>
            <p>
              <Link 
                to={ '/signup'}
                state={{ signUp: false }}
              >
                {t("navbar.userSignedOut")}
              </Link>
            </p>
            <button type='button'>
            <Link 
                to={ '/signup'}
                state={{ signUp: true }}
              >
                {t("navbar.userSignedOut2")}
              </Link>
            </button>
          </>
        )}

      
    </>
  )
}

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [userSignedIn, setUserSignedIn] = useState(false);

  useEffect(() => {
    // console.log(toggleMenu);
  }, [toggleMenu]);


  return (
    <div className='gpt3__navbar'>
      <div className='gpt3__navbar-links'>
        <div className='gpt3__navbar-links_logo'>
          <img src={logo} alt="logo" />
        </div>
        <div className='gpt3__navbar-links_container'>
          <Menu userSignedIn={userSignedIn} />
        </div>
      </div>
      <div className='gpt3__navbar-sign'>
        <SignUpIn
          userSignedIn={userSignedIn}
          setUserSignedIn={setUserSignedIn}
        />
      </div>
      <div className='gpt3__navbar-menu'>
        {toggleMenu ? (
          <RiCloseLine color='#000' size={27} onClick={() => setToggleMenu(false)} />
        ) : (
          <RiMenu3Line color='#000' size={27} onClick={() => setToggleMenu(true)} />
        )}
        {toggleMenu && (
          <div className='gpt3__navbar-menu_container scale-up-center'>
            <div className='gpt3__navbar-menu_container-links'>
              <Menu userSignedIn={userSignedIn} />
                <div className='gpt3__navbar-menu_container-links-sign'>
                  <SignUpIn
                    userSignedIn={userSignedIn}
                    setUserSignedIn={setUserSignedIn}
                  />
                </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar