import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './faq.css'

function EmailUs() {

  const [copySuccess, setCopySuccess] = useState('');

  const { t } = useTranslation("global");
  const emailUs = 'FAQ.emailUs'

  const email = "support@sonictranscript.com";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email);
    setCopySuccess(t(emailUs+".copySuccess"));
  };

  return (
    <div>
        <p className='faqTextLittleMargin'>
             {t(emailUs+".description")}
             <button className='copyButton' onClick={copyToClipboard}><FontAwesomeIcon icon={faCopy} size="lg" /></button>
        </p>
            {copySuccess === t(emailUs+".copySuccess") && <p className='faqTextLittleMargin'>{copySuccess}</p>}
    </div>
  )
}

export default EmailUs