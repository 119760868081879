import React from 'react';
import { createRoot } from 'react-dom/client'
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import App from './App';
import { global_en, global_es, global_pt, global_hi } from './languages';
import './index.css';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        global: global_en,
      },
      es: {
        global: global_es,
      },
      pt: {
        global: global_pt,
      },
      hi: {
        global: global_hi,
      }
    },
  });


const rootElement = createRoot(document.getElementById('root'));
rootElement.render(
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>
);