import React, { useState, useEffect } from 'react'; //useEffect, 
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
// import secureLocalStorage from 'react-secure-storage';
import { getDoc, doc, updateDoc, addDoc, collection, increment } from "firebase/firestore";
import axios from 'axios';
import pdfToText from "react-pdftotext";
import mammoth from 'mammoth';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import htmlToDocx from 'html-docx-js/dist/html-docx';
import { useTranslation } from 'react-i18next';
// import LanguageDetect from 'languagedetect';
// import JSZip from 'jszip';
// import { xml2js, js2xml } from 'xml-js';
import {auth, db} from '../../firebase';
// import logo from "../../assets/stLogo.png"
import './translateDropBox.css';

const TranslateDropBox = ({handleDisabledTab, translateDropBoxState, updateTranslateDropBoxState}) => {

  const navigate = useNavigate();
  const characterToMinutesConversion = 1500
  const [textToTranslate, setTextToTranslate] = useState(translateDropBoxState.textToTranslate);
  const [dragging, setDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(translateDropBoxState.selectedFile);
  const [fileName, setFileName] = useState(translateDropBoxState.fileName);
  const [fileMinutes, setFileMinutes] = useState(translateDropBoxState.fileMinutes);
  const [selectLanguagesOptionsFrom, setSelectLanguagesOptionsFrom] = useState(translateDropBoxState.selectLanguagesOptionsFrom);
  const [selectLanguagesOptionsTo, setSelectLanguagesOptionsTo] = useState(translateDropBoxState.selectLanguagesOptionsTo);
  const [selectedOption, setSelectedOption] = useState(translateDropBoxState.selectedOption);
  const [error, setError] = useState(translateDropBoxState.error);
  const [uploadProgress, setUploadProgress] = useState(translateDropBoxState.uploadProgress);
  const [downloadProgress, setDownloadProgress] = useState(translateDropBoxState.downloadProgress);
  const [switchProgressBar, setSwitchProgressBar] = useState(translateDropBoxState.switchProgressBar);
  const [submitting, setSubmitting] = useState(translateDropBoxState.submitting);
  const [disabled, setDisabled] = useState(translateDropBoxState.disabled);

  const { t } = useTranslation("global");

  const options = [
      { value: ["text"], label: t('audioDrop.options.textFile') },
      { value: ["pdf"], label: t('audioDrop.options.pdf') },
      { value: ["word"], label: t('audioDrop.options.wordDocument') },
      { value: ["text", "pdf"], label: t('audioDrop.options.textAndPdf') },
      { value: ["text", "word"], label: t('audioDrop.options.textAndWord') },
      { value: ["pdf", "word"], label: t('audioDrop.options.pdfAndWord') },
      { value: ["text", "pdf", "word"], label: t('audioDrop.options.all') },
  ]
  
  const languagesOptionsFrom = [
    { value: 'af', label: t('translateBox.languages.af') },
    { value: 'sq', label: t('translateBox.languages.sq') },
    { value: 'am', label: t('translateBox.languages.am') },
    { value: 'ar', label: t('translateBox.languages.ar') },
    { value: 'hy', label: t('translateBox.languages.hy') },
    { value: 'as', label: t('translateBox.languages.as') },
    { value: 'az', label: t('translateBox.languages.az') },
    { value: 'bn', label: t('translateBox.languages.bn') },
    { value: 'ba', label: t('translateBox.languages.ba') },
    { value: 'eu', label: t('translateBox.languages.eu') },
    { value: 'bho', label: t('translateBox.languages.bho') },
    { value: 'brx', label: t('translateBox.languages.brx') },
    { value: 'bs', label: t('translateBox.languages.bs') },
    { value: 'bg', label: t('translateBox.languages.bg') },
    { value: 'yue', label: t('translateBox.languages.yue') },
    { value: 'ca', label: t('translateBox.languages.ca') },
    { value: 'lzh', label: t('translateBox.languages.lzh') },
    { value: 'zh-Hans', label: t('translateBox.languages.zh-Hans') },
    { value: 'zh-Hant', label: t('translateBox.languages.zh-Hant') },
    { value: 'sn', label: t('translateBox.languages.sn') },
    { value: 'hr', label: t('translateBox.languages.hr') },
    { value: 'cs', label: t('translateBox.languages.cs') },
    { value: 'da', label: t('translateBox.languages.da') },
    { value: 'prs', label: t('translateBox.languages.prs') },
    { value: 'dv', label: t('translateBox.languages.dv') },
    { value: 'doi', label: t('translateBox.languages.doi') },
    { value: 'nl', label: t('translateBox.languages.nl') },
    { value: 'en', label: t('translateBox.languages.en') },
    { value: 'et', label: t('translateBox.languages.et') },
    { value: 'fo', label: t('translateBox.languages.fo') },
    { value: 'fj', label: t('translateBox.languages.fj') },
    { value: 'fil', label: t('translateBox.languages.fil') },
    { value: 'fi', label: t('translateBox.languages.fi') },
    { value: 'fr', label: t('translateBox.languages.fr') },
    { value: 'fr-ca', label: t('translateBox.languages.fr-ca') },
    { value: 'gl', label: t('translateBox.languages.gl') },
    { value: 'ka', label: t('translateBox.languages.ka') },
    { value: 'de', label: t('translateBox.languages.de') },
    { value: 'el', label: t('translateBox.languages.el') },
    { value: 'gu', label: t('translateBox.languages.gu') },
    { value: 'ht', label: t('translateBox.languages.ht') },
    { value: 'ha', label: t('translateBox.languages.ha') },
    { value: 'he', label: t('translateBox.languages.he') },
    { value: 'hi', label: t('translateBox.languages.hi') },
    { value: 'mww', label: t('translateBox.languages.mww') },
    { value: 'hu', label: t('translateBox.languages.hu') },
    { value: 'is', label: t('translateBox.languages.is') },
    { value: 'ig', label: t('translateBox.languages.ig') },
    { value: 'id', label: t('translateBox.languages.id') },
    { value: 'ikt', label: t('translateBox.languages.ikt') },
    { value: 'iu', label: t('translateBox.languages.iu') },
    { value: 'iu-Latn', label: t('translateBox.languages.iu-Latn') },
    { value: 'ga', label: t('translateBox.languages.ga') },
    { value: 'it', label: t('translateBox.languages.it') },
    { value: 'ja', label: t('translateBox.languages.ja') },
    { value: 'kn', label: t('translateBox.languages.kn') },
    { value: 'ks', label: t('translateBox.languages.ks') },
    { value: 'kk', label: t('translateBox.languages.kk') },
    { value: 'km', label: t('translateBox.languages.km') },
    { value: 'rw', label: t('translateBox.languages.rw') },
    { value: 'tlh-Latn', label: t('translateBox.languages.tlh-Latn') },
    { value: 'tlh-Piqd', label: t('translateBox.languages.tlh-Piqd') },
    { value: 'gom', label: t('translateBox.languages.gom') },
    { value: 'ko', label: t('translateBox.languages.ko') },
    { value: 'ku', label: t('translateBox.languages.ku') },
    { value: 'kmr', label: t('translateBox.languages.kmr') },
    { value: 'ky', label: t('translateBox.languages.ky') },
    { value: 'lo', label: t('translateBox.languages.lo') },
    { value: 'lv', label: t('translateBox.languages.lv') },
    { value: 'lt', label: t('translateBox.languages.lt') },
    { value: 'ln', label: t('translateBox.languages.ln') },
    { value: 'dsb', label: t('translateBox.languages.dsb') },
    { value: 'lug', label: t('translateBox.languages.lug') },
    { value: 'mk', label: t('translateBox.languages.mk') },
    { value: 'mai', label: t('translateBox.languages.mai') },
    { value: 'mg', label: t('translateBox.languages.mg') },
    { value: 'ms', label: t('translateBox.languages.ms') },
    { value: 'ml', label: t('translateBox.languages.ml') },
    { value: 'mt', label: t('translateBox.languages.mt') },
    { value: 'mi', label: t('translateBox.languages.mi') },
    { value: 'mr', label: t('translateBox.languages.mr') },
    { value: 'mn-Cyrl', label: t('translateBox.languages.mn-Cyrl') },
    { value: 'mn-Mong', label: t('translateBox.languages.mn-Mong') },
    { value: 'my', label: t('translateBox.languages.my') },
    { value: 'ne', label: t('translateBox.languages.ne') },
    { value: 'nb', label: t('translateBox.languages.nb') },
    { value: 'ny', label: t('translateBox.languages.ny') },
    { value: 'or', label: t('translateBox.languages.or') },
    { value: 'ps', label: t('translateBox.languages.ps') },
    { value: 'fa', label: t('translateBox.languages.fa') },
    { value: 'pl', label: t('translateBox.languages.pl') },
    { value: 'pt', label: t('translateBox.languages.pt') },
    { value: 'pt-pt', label: t('translateBox.languages.pt-pt') },
    { value: 'pa', label: t('translateBox.languages.pa') },
    { value: 'otq', label: t('translateBox.languages.otq') },
    { value: 'ro', label: t('translateBox.languages.ro') },
    { value: 'run', label: t('translateBox.languages.run') },
    { value: 'ru', label: t('translateBox.languages.ru') },
    { value: 'sm', label: t('translateBox.languages.sm') },
    { value: 'sr-Cyrl', label: t('translateBox.languages.sr-Cyrl') },
    { value: 'sr-Latn', label: t('translateBox.languages.sr-Latn') },
    { value: 'st', label: t('translateBox.languages.st') },
    { value: 'nso', label: t('translateBox.languages.nso') },
    { value: 'tn', label: t('translateBox.languages.tn') },
    { value: 'sd', label: t('translateBox.languages.sd') },
    { value: 'si', label: t('translateBox.languages.si') },
    { value: 'sk', label: t('translateBox.languages.sk') },
    { value: 'sl', label: t('translateBox.languages.sl') },
    { value: 'so', label: t('translateBox.languages.so') },
    { value: 'es', label: t('translateBox.languages.es') },
    { value: 'sw', label: t('translateBox.languages.sw') },
    { value: 'sv', label: t('translateBox.languages.sv') },
    { value: 'ty', label: t('translateBox.languages.ty') },
    { value: 'ta', label: t('translateBox.languages.ta') },
    { value: 'tt', label: t('translateBox.languages.tt') },
    { value: 'te', label: t('translateBox.languages.te') },
    { value: 'th', label: t('translateBox.languages.th') },
    { value: 'bo', label: t('translateBox.languages.bo') },
    { value: 'ti', label: t('translateBox.languages.ti') },
    { value: 'to', label: t('translateBox.languages.to') },
    { value: 'tr', label: t('translateBox.languages.tr') },
    { value: 'tk', label: t('translateBox.languages.tk') },
    { value: 'uk', label: t('translateBox.languages.uk') },
    { value: 'hsb', label: t('translateBox.languages.hsb') },
    { value: 'ur', label: t('translateBox.languages.ur') },
    { value: 'ug', label: t('translateBox.languages.ug') },
    { value: 'uz', label: t('translateBox.languages.uz') },
    { value: 'vi', label: t('translateBox.languages.vi') },
    { value: 'cy', label: t('translateBox.languages.cy') },
    { value: 'xh', label: t('translateBox.languages.xh') },
    { value: 'yo', label: t('translateBox.languages.yo') },
    { value: 'yua', label: t('translateBox.languages.yua') },
    { value: 'zu', label: t('translateBox.languages.zu') }
];
  
  const languagesOptionsTo = [
    { value: 'af', label: t('translateBox.languages.af') },
    { value: 'sq', label: t('translateBox.languages.sq') },
    { value: 'am', label: t('translateBox.languages.am') },
    { value: 'ar', label: t('translateBox.languages.ar') },
    { value: 'hy', label: t('translateBox.languages.hy') },
    { value: 'as', label: t('translateBox.languages.as') },
    { value: 'az', label: t('translateBox.languages.az') },
    { value: 'bn', label: t('translateBox.languages.bn') },
    { value: 'ba', label: t('translateBox.languages.ba') },
    { value: 'eu', label: t('translateBox.languages.eu') },
    { value: 'bho', label: t('translateBox.languages.bho') },
    { value: 'brx', label: t('translateBox.languages.brx') },
    { value: 'bs', label: t('translateBox.languages.bs') },
    { value: 'bg', label: t('translateBox.languages.bg') },
    { value: 'yue', label: t('translateBox.languages.yue') },
    { value: 'ca', label: t('translateBox.languages.ca') },
    { value: 'lzh', label: t('translateBox.languages.lzh') },
    { value: 'zh-Hans', label: t('translateBox.languages.zh-Hans') },
    { value: 'zh-Hant', label: t('translateBox.languages.zh-Hant') },
    { value: 'sn', label: t('translateBox.languages.sn') },
    { value: 'hr', label: t('translateBox.languages.hr') },
    { value: 'cs', label: t('translateBox.languages.cs') },
    { value: 'da', label: t('translateBox.languages.da') },
    { value: 'prs', label: t('translateBox.languages.prs') },
    { value: 'dv', label: t('translateBox.languages.dv') },
    { value: 'doi', label: t('translateBox.languages.doi') },
    { value: 'nl', label: t('translateBox.languages.nl') },
    { value: 'en', label: t('translateBox.languages.en') },
    { value: 'et', label: t('translateBox.languages.et') },
    { value: 'fo', label: t('translateBox.languages.fo') },
    { value: 'fj', label: t('translateBox.languages.fj') },
    { value: 'fil', label: t('translateBox.languages.fil') },
    { value: 'fi', label: t('translateBox.languages.fi') },
    { value: 'fr', label: t('translateBox.languages.fr') },
    { value: 'fr-ca', label: t('translateBox.languages.fr-ca') },
    { value: 'gl', label: t('translateBox.languages.gl') },
    { value: 'ka', label: t('translateBox.languages.ka') },
    { value: 'de', label: t('translateBox.languages.de') },
    { value: 'el', label: t('translateBox.languages.el') },
    { value: 'gu', label: t('translateBox.languages.gu') },
    { value: 'ht', label: t('translateBox.languages.ht') },
    { value: 'ha', label: t('translateBox.languages.ha') },
    { value: 'he', label: t('translateBox.languages.he') },
    { value: 'hi', label: t('translateBox.languages.hi') },
    { value: 'mww', label: t('translateBox.languages.mww') },
    { value: 'hu', label: t('translateBox.languages.hu') },
    { value: 'is', label: t('translateBox.languages.is') },
    { value: 'ig', label: t('translateBox.languages.ig') },
    { value: 'id', label: t('translateBox.languages.id') },
    { value: 'ikt', label: t('translateBox.languages.ikt') },
    { value: 'iu', label: t('translateBox.languages.iu') },
    { value: 'iu-Latn', label: t('translateBox.languages.iu-Latn') },
    { value: 'ga', label: t('translateBox.languages.ga') },
    { value: 'it', label: t('translateBox.languages.it') },
    { value: 'ja', label: t('translateBox.languages.ja') },
    { value: 'kn', label: t('translateBox.languages.kn') },
    { value: 'ks', label: t('translateBox.languages.ks') },
    { value: 'kk', label: t('translateBox.languages.kk') },
    { value: 'km', label: t('translateBox.languages.km') },
    { value: 'rw', label: t('translateBox.languages.rw') },
    { value: 'tlh-Latn', label: t('translateBox.languages.tlh-Latn') },
    { value: 'tlh-Piqd', label: t('translateBox.languages.tlh-Piqd') },
    { value: 'gom', label: t('translateBox.languages.gom') },
    { value: 'ko', label: t('translateBox.languages.ko') },
    { value: 'ku', label: t('translateBox.languages.ku') },
    { value: 'kmr', label: t('translateBox.languages.kmr') },
    { value: 'ky', label: t('translateBox.languages.ky') },
    { value: 'lo', label: t('translateBox.languages.lo') },
    { value: 'lv', label: t('translateBox.languages.lv') },
    { value: 'lt', label: t('translateBox.languages.lt') },
    { value: 'ln', label: t('translateBox.languages.ln') },
    { value: 'dsb', label: t('translateBox.languages.dsb') },
    { value: 'lug', label: t('translateBox.languages.lug') },
    { value: 'mk', label: t('translateBox.languages.mk') },
    { value: 'mai', label: t('translateBox.languages.mai') },
    { value: 'mg', label: t('translateBox.languages.mg') },
    { value: 'ms', label: t('translateBox.languages.ms') },
    { value: 'ml', label: t('translateBox.languages.ml') },
    { value: 'mt', label: t('translateBox.languages.mt') },
    { value: 'mi', label: t('translateBox.languages.mi') },
    { value: 'mr', label: t('translateBox.languages.mr') },
    { value: 'mn-Cyrl', label: t('translateBox.languages.mn-Cyrl') },
    { value: 'mn-Mong', label: t('translateBox.languages.mn-Mong') },
    { value: 'my', label: t('translateBox.languages.my') },
    { value: 'ne', label: t('translateBox.languages.ne') },
    { value: 'nb', label: t('translateBox.languages.nb') },
    { value: 'ny', label: t('translateBox.languages.ny') },
    { value: 'or', label: t('translateBox.languages.or') },
    { value: 'ps', label: t('translateBox.languages.ps') },
    { value: 'fa', label: t('translateBox.languages.fa') },
    { value: 'pl', label: t('translateBox.languages.pl') },
    { value: 'pt', label: t('translateBox.languages.pt') },
    { value: 'pt-pt', label: t('translateBox.languages.pt-pt') },
    { value: 'pa', label: t('translateBox.languages.pa') },
    { value: 'otq', label: t('translateBox.languages.otq') },
    { value: 'ro', label: t('translateBox.languages.ro') },
    { value: 'run', label: t('translateBox.languages.run') },
    { value: 'ru', label: t('translateBox.languages.ru') },
    { value: 'sm', label: t('translateBox.languages.sm') },
    { value: 'sr-Cyrl', label: t('translateBox.languages.sr-Cyrl') },
    { value: 'sr-Latn', label: t('translateBox.languages.sr-Latn') },
    { value: 'st', label: t('translateBox.languages.st') },
    { value: 'nso', label: t('translateBox.languages.nso') },
    { value: 'tn', label: t('translateBox.languages.tn') },
    { value: 'sd', label: t('translateBox.languages.sd') },
    { value: 'si', label: t('translateBox.languages.si') },
    { value: 'sk', label: t('translateBox.languages.sk') },
    { value: 'sl', label: t('translateBox.languages.sl') },
    { value: 'so', label: t('translateBox.languages.so') },
    { value: 'es', label: t('translateBox.languages.es') },
    { value: 'sw', label: t('translateBox.languages.sw') },
    { value: 'sv', label: t('translateBox.languages.sv') },
    { value: 'ty', label: t('translateBox.languages.ty') },
    { value: 'ta', label: t('translateBox.languages.ta') },
    { value: 'tt', label: t('translateBox.languages.tt') },
    { value: 'te', label: t('translateBox.languages.te') },
    { value: 'th', label: t('translateBox.languages.th') },
    { value: 'bo', label: t('translateBox.languages.bo') },
    { value: 'ti', label: t('translateBox.languages.ti') },
    { value: 'to', label: t('translateBox.languages.to') },
    { value: 'tr', label: t('translateBox.languages.tr') },
    { value: 'tk', label: t('translateBox.languages.tk') },
    { value: 'uk', label: t('translateBox.languages.uk') },
    { value: 'hsb', label: t('translateBox.languages.hsb') },
    { value: 'ur', label: t('translateBox.languages.ur') },
    { value: 'ug', label: t('translateBox.languages.ug') },
    { value: 'uz', label: t('translateBox.languages.uz') },
    { value: 'vi', label: t('translateBox.languages.vi') },
    { value: 'cy', label: t('translateBox.languages.cy') },
    { value: 'xh', label: t('translateBox.languages.xh') },
    { value: 'yo', label: t('translateBox.languages.yo') },
    { value: 'yua', label: t('translateBox.languages.yua') },
    { value: 'zu', label: t('translateBox.languages.zu') }
];

  function findNestedText(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return null; // Not an object or is null, so return null
    }
  
    for (const key in obj) {
      if (key === 'text') {
        return obj[key]; // Found the text property, return its value
      } else if (typeof obj[key] === 'object') {
        const result = findNestedText(obj[key]); // Recursively search in nested object
        if (result) {
          return result; // Found the text property in nested object, return its value
        }
      }
    }
  
    return null; // Text property not found
  }

  const readTextFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const minutes = Math.ceil(text.length / characterToMinutesConversion);
      setFileMinutes(minutes);
      setTextToTranslate(text);
      // const langCode = languageDetect.detect(text, 1);
      // console.log(langCode, "langCode")

      // if(langCode.length > 0) {
      //   const code = customLangCodeToLangDetect[langCode[0][0].toLowerCase()];
      //   const option = languagesOptionsFrom.find((option) => option.value === code);
      //   setSelectLanguagesOptionsFrom(option);
      // }
      
    };
    reader.readAsText(file);
  }

  const readWordFile = (file) => {
    // const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // console.log(e.target.result, "e.target.result")
        const arrayBuffer = e.target.result;
        mammoth.extractRawText({ arrayBuffer: arrayBuffer })
          .then((result) => {
            // console.log(result, "result")
            if(result.value.length <= 0) {
              alert(t('translateBox.noTextAlert'));
            }
            // console.log(result.value.length); // The extracted text
            const minutes = Math.ceil(result.value.length / characterToMinutesConversion);
            setFileMinutes(minutes);
            setTextToTranslate(result.value);
            // language detection
            // const langCode = languageDetect.detect(result.value, 1);
            // if(langCode.length > 0) {
            //   const code = customLangCodeToLangDetect[langCode[0][0].toLowerCase()];
            //   const option = languagesOptionsFrom.find((option) => option.value === code);
            //   setSelectLanguagesOptionsFrom(option);
            // }
            
          })
          .catch((err) => {
            console.error(err);
            setError(t('translateBox.extractFail'));
          });
      };
      reader.readAsArrayBuffer(file);
    }
  }

  const readPdfFile = (file) => {
    pdfToText(file)
    .then((text) => {
      const minutes = Math.ceil(text.length / characterToMinutesConversion);
      setFileMinutes(minutes);
      setTextToTranslate(text);
      //language detection
      // const langCode = languageDetect.detect(text, 1);
      // if(langCode.length > 0) {
      //   const code = customLangCodeToLangDetect[langCode[0][0].toLowerCase()];
      //   const option = languagesOptionsFrom.find((option) => option.value === code);
      //   setSelectLanguagesOptionsFrom(option);
      // }
    })
    .catch((error) => setError(t('translateBox.pdfError')));
  }

  const handleSelectLanguageFromChange = option => {
    // if (window.fbq) {
    //     window.fbq('trackCustom', 'LanguageTypeNoSpeakerLabels', {content_name: option.label});
    // }
    setSelectLanguagesOptionsFrom(option);
  };

  const handleSelectLanguageToChange = option => {
    // if (window.fbq) {
    //     window.fbq('trackCustom', 'LanguageTypeNoSpeakerLabels', {content_name: option.label});
    // }
    setSelectLanguagesOptionsTo(option);
  };

  const handleFileChange = (event) => {
    if (auth.currentUser == null) {
      alert(t('audioDrop.signInAlert'));
      navigate('/signup')
      return;
    }
    // if there is a transcription in progress, get the transcript id
    if(!auth.currentUser.emailVerified){
        alert(t('audioDrop.verifyEmail'));
        navigate('/profile')
        return;
    }
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileName(file.name.split('.')[0]);
    switch (file.type) {
      case 'text/plain':
        readTextFile(file);
        break;
      case 'application/pdf':
        readPdfFile(file);
        break;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        // console.log(file.type, "file type")
        readWordFile(file, event);
        break;
      default:
        setError(new Error('Invalid file type'));
        return
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (event) => {
      event.preventDefault();
      setDragging(false);
  };

  const handleDrop = (event) => {
      event.preventDefault();
      setDragging(false);
      const file = event.dataTransfer.files[0];

      if (file) {
        handleFileChange({ target: { files: [file] } });
      }
  };

  const handleSelectChange = option => {
    if (window.fbq) {
        window.fbq('trackCustom', 'FileType', {content_name: option.label});
    }
    setSelectedOption(option);
  };

  const handleReset = () => {
    setSelectedFile(null);
    setFileName('');
    setFileMinutes(0);
    setTextToTranslate('');
    setSelectLanguagesOptionsFrom('');
    setSelectLanguagesOptionsTo('');
    setSelectedOption(null);
    setUploadProgress(0);
    setDownloadProgress(0);
    setError(null);
    setSubmitting(false);
    setSwitchProgressBar(false);
    setDisabled(false);
  };

  const createDocument = async (transcriptionText, type, fileName) => {
    switch (type) {
        case "text":
            const blob = new Blob([transcriptionText], {type: "text/plain;charset=utf-8"});
            saveAs(blob, fileName+".txt");
            break;
        case "pdf":
            const doc = new jsPDF();
            doc.setFontSize(12); // Set the font size to 10 points
            const maxWidth = 180; // Maximum width in points
            const lineHeight = 10; // Line height in points, adjust as needed
            const pageHeight = doc.internal.pageSize.height; // Page height in points
            const lines = doc.splitTextToSize(transcriptionText, maxWidth); // Split the text
            let y = 20; // Initial y position, adjust as needed
            for (let i = 0; i < lines.length; i++) {
                if (y + lineHeight > pageHeight) {
                    doc.addPage();
                    y = 20; // Reset y position to top of the new page, adjust as needed
                }
                doc.text(lines[i], 20, y); // Adjust the x position as needed
                y += lineHeight;
            }
            const blob2 = doc.output('blob');
            saveAs(blob2, fileName+".pdf");
            break;
        case "word":
            // Convert the text to HTML
            const html = `<p>${transcriptionText}</p>`;
            
            // Convert the HTML to a Word document
            const docx = htmlToDocx.asBlob(html);
            saveAs(docx, fileName+'.docx');
            break;
        default:
            break;
      }
  };

  const translateText = async (text, sl, tl, fileTypes) => {
    
    if(!fileTypes) {
      alert(t('translateBox.noFileType'));
      return;
    }
    if(!text) {
      alert(t('translateBox.noText'));
      return;
    }
    if(sl.value === tl.value) {
      alert(t('translateBox.sameLanguage'));
      return;
    }
    if(!sl || !tl) {
      alert(t('translateBox.selectLanguages'));
      return;
    }
    setDownloadProgress(0);
    setUploadProgress(0);
    setSwitchProgressBar(false);
    setError(null);
    setSubmitting(true);
    setDisabled(true)
    handleDisabledTab(true);
    
    const uid = auth.currentUser.uid;
    const userDoc = await getDoc(doc(db, "users", uid));
    const userData = userDoc.data();
    const corporation = userData.corporation;
    let userMinutes = userData.minutes;
    if(corporation) {
        const corpDoc = await getDoc(doc(db, "corporations", corporation));
        userMinutes = corpDoc.data().minutes;
    }

    if (userMinutes < fileMinutes) {
      alert('You do not have enough minutes to upload this file.');
      navigate('/profile');
      return;
    }

    // Deduct user minutes before sending the request
  try {
    if (corporation) {
      await updateDoc(doc(db, "corporations", corporation), {
        minutes: increment(-fileMinutes),
      });
    } else {
      await updateDoc(doc(db, "users", auth.currentUser.uid), {
        minutes: increment(-fileMinutes),
      });
    }
  } catch (error) {
    setError(error);
    setSubmitting(false);
    setDisabled(false);
    handleDisabledTab(false);
    return;
  }

    let lastUploadTime = Date.now();
    let lastDownloadTime = Date.now();
    const delayTime = 25; // Delay of 50ms
    const delaySwitch = 500; // Delay of 500ms
    
    // console.log(text, sl, tl, "text, sl, tl")
    // test eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZDE3YTE4MzUtYzhlNy00Y2NmLTgzZjMtYmYzZWZiMWE3Y2NkIiwidHlwZSI6InNhbmRib3hfYXBpX3Rva2VuIn0.QoF5z6t0Vlj5VK0Tq0h8guKtq612vOpSFJ0sYiUsz2g
    // production eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZDE3YTE4MzUtYzhlNy00Y2NmLTgzZjMtYmYzZWZiMWE3Y2NkIiwidHlwZSI6ImFwaV90b2tlbiJ9.99DJVYe6iydTVaiGTGMhVbNYXC5-s0D7dwg6LsDKs_c
    const options = {
      method: "POST",
      url: "https://api.edenai.run/v2/translation/automatic_translation",
      headers: {
        authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZDE3YTE4MzUtYzhlNy00Y2NmLTgzZjMtYmYzZWZiMWE3Y2NkIiwidHlwZSI6ImFwaV90b2tlbiJ9.99DJVYe6iydTVaiGTGMhVbNYXC5-s0D7dwg6LsDKs_c",
      },
      data: {
        providers: "microsoft",
        text: text,
        source_language: sl.value,
        target_language: tl.value,
      },
      onUploadProgress: (progressEvent) => {
        const now = Date.now();
        if (now - lastUploadTime > delayTime) {
          lastUploadTime = now;
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setTimeout(() => {
            setUploadProgress(percentCompleted);
          }, delayTime);
          if(percentCompleted === 100) {
            setTimeout(() => {
              setSwitchProgressBar(true);
            }, delaySwitch);
          }
        }
      },
      onDownloadProgress: (progressEvent) => {
        const now = Date.now();
        if (now - lastDownloadTime > delayTime) {
          lastDownloadTime = now;
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setTimeout(() => {
            setDownloadProgress(percentCompleted);
          }, delayTime); 
        }
      },
    };

    

    axios.request(options)
      .then(async (response) => {

        setDisabled(false);
        handleDisabledTab(false);
        const translatedText = response.data; // Adjust according to the actual response structure
        const text = findNestedText(translatedText);
        fileTypes.forEach((type) => {
          createDocument(text, type, fileName+" "+tl.label)
        });
        
        if(corporation) {
          await addDoc(collection(db, "corporations", corporation, "log"), {
              fileName: fileName,
              fileType: selectedOption.value,
              date: new Date(),
              fileMinutes: fileMinutes,
              totalMinutes: userMinutes,
              language: selectLanguagesOptionsTo.value,
              email: auth.currentUser.email,
              serviceType: 'translation',
          });
      } else {
          await addDoc(collection(db, "users", auth.currentUser.uid, "log"), {
              fileName: fileName,
              fileType: selectedOption.value,
              date: new Date(),
              fileMinutes: fileMinutes,
              totalMinutes: userMinutes,
              language: selectLanguagesOptionsTo.value,
              serviceType: 'translation',
          });
      }
    })
    .catch(async (error) => {
      if (corporation) {
        await updateDoc(doc(db, "corporations", corporation), {
          minutes: increment(fileMinutes),
        });
      } else {
        await updateDoc(doc(db, "users", auth.currentUser.uid), {
          minutes: increment(fileMinutes),
        });
      }
      setError(error);
      setSubmitting(false);
      setDisabled(false);
      handleDisabledTab(false);
    });
  };

  useEffect(() => {

    updateTranslateDropBoxState({
      textToTranslate,
      selectedFile,
      fileName,
      fileMinutes,
      selectLanguagesOptionsFrom,
      selectLanguagesOptionsTo,
      selectedOption,
      error,
      uploadProgress,
      downloadProgress,
      switchProgressBar,
      submitting,
      disabled,
    })
    
  }, [textToTranslate, selectLanguagesOptionsFrom, selectLanguagesOptionsTo, selectedOption, error, uploadProgress, downloadProgress, switchProgressBar, submitting, disabled, fileMinutes, fileName, selectedFile]);

  return (
    <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className='container'
        style={{ border: dragging ? '2px solid blue' : '2px solid gray'}}
    >
      {selectedFile ? (
        <div>
          <p>{t('audioDrop.selectedFile')}<strong>{fileName}</strong></p>
          <p>{t('audioDrop.fileMinutes')}<strong>{fileMinutes}</strong></p>
          <Select
              value={selectedOption}
              onChange={handleSelectChange}
              options={options}
              defaultValue={options[0]}
              styles={{
                  option: (provided) => ({
                    ...provided,
                    textAlign: 'center'
                  }),
                  control: (provided) => ({
                    ...provided,
                    maxHeight: '50px',
                    marginTop: '10px',
                    zIndex: 1000,
                  }),
                }}
              menuPortalTarget={document.body}
              menuPosition='fixed'
              placeholder={t('audioDrop.placeHolderFiles')}
          />
          <div style={{marginTop: 10}}>
            <label>Translate From:</label>
            <Select
                value={selectLanguagesOptionsFrom}
                onChange={handleSelectLanguageFromChange}
                options={languagesOptionsFrom}
                defaultValue={languagesOptionsFrom[0]}
                styles={{
                    option: (provided) => ({
                    ...provided,
                    textAlign: 'center'
                    }),
                    control: (provided) => ({
                    ...provided,
                    maxHeight: '50px',
                    marginTop: '10px',
                    zIndex: 2000,
                    }),
                }}
                menuPortalTarget={document.body}
                menuPosition='fixed'
                placeholder={t('translateBox.translateFromPlaceholder')}
            />
          </div>
          <div style={{marginTop: 10}}>
            <label>Translate To:</label>
            <Select
                value={selectLanguagesOptionsTo}
                onChange={handleSelectLanguageToChange}
                options={languagesOptionsTo}
                defaultValue={languagesOptionsTo[0]}
                styles={{
                    option: (provided) => ({
                    ...provided,
                    textAlign: 'center'
                    }),
                    control: (provided) => ({
                    ...provided,
                    maxHeight: '50px',
                    marginTop: '10px',
                    zIndex: 2000,
                    }),
                }}
                menuPortalTarget={document.body}
                menuPosition='fixed'
                placeholder={t('translateBox.translateToPlaceholder')}
            />
          </div>
          <button className={"submitButtonTranslate"} disabled={disabled} onClick={() => translateText(textToTranslate, selectLanguagesOptionsFrom, selectLanguagesOptionsTo, selectedOption.value)}>{t('audioDrop.send')}</button>
          {downloadProgress === 100 && (
              <button className={"submitButtonTranslate"} style={{marginLeft: 10}} onClick={handleReset}>{t('audioDrop.reset')}</button>
          )}
          {submitting && (
            <>
              <div style={{marginTop: 10}}><strong>{t('translateBox.translateWarning')}</strong></div>
              {!switchProgressBar ? (
                <div style={{marginTop: 10}}>
                  <label>{t('translateBox.uploadProgress')}</label>
                  <div className="progress-bar">
                    <div className="progress-bar-inner" style={{ width: `${uploadProgress}%` }}></div>
                  </div>
                </div>
              ):(
                <div style={{marginTop: 10}}>
                  <label>{t('translateBox.downloadProgress')}</label>
                  <div className="progress-bar">
                    <div className="progress-bar-inner" style={{ width: `${downloadProgress}%` }}></div>
                  </div>
                </div>
              )}
            </>
          )}
          
          {error && <p>Error: {error.message}</p>}
        </div>
      ):(
        <div>
          <h2>{t('audioDrop.uploadTitle')}</h2>
          <p>{t('translateBox.uploadText')}</p>
          <button className="submitButtonTranslate" onClick={() => document.getElementById('fileInput').click()}>{t('audioDrop.selectFile')}</button>
          <input type="file" id="fileInput" accept="text/plain, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={handleFileChange} style={{ display: 'none' }} />
          {error && <p>Error: {error.message}</p>}
        </div>
      )}
      
    </div>
  );
};

export default TranslateDropBox;